import React, { useState } from 'react';
import styles from './PasswordResetMainContent.module.css';
import AsterImage from './Images/ContrasenaAster.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';

const PasswordResetMainContent = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Las contraseñas no coinciden.');
      return;
    }

    const token = searchParams.get('token');

    try {
      const response = await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/reset-password', {
        token,
        password
      });
      setMessage(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setMessage('Error al restablecer la contraseña.');
    }
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.passwordResetBox}>
        <div className={styles.passwordResetTitle}>Cambiar contraseña</div>
        <div className={styles.asterContainer}>
          <img src={AsterImage} alt="Aster" className={styles.asterImage} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.passwordInputContainer}>
            <input 
              type={showPassword ? "text" : "password"} 
              placeholder="Contraseña nueva" 
              value={password} 
              onChange={handlePasswordChange} 
              className={styles.passwordInput}
            />
            <span
              className={styles.eyeIcon}
              onClick={toggleShowPassword}
            >
              <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
          </div>
          <div className={styles.passwordInputContainer}>
            <input 
              type={showConfirmPassword ? "text" : "password"} 
              placeholder="Confirmar contraseña" 
              value={confirmPassword} 
              onChange={handleConfirmPasswordChange} 
              className={styles.passwordInput}
            />
            <span
              className={styles.eyeIcon}
              onClick={toggleShowConfirmPassword}
            >
              <i className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </span>
          </div>
          <button type="submit" className={styles.submitButton}>Cambiar contraseña</button>
        {message && <div className={styles.message}>{message}</div>}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetMainContent;
