import React from 'react';
import styles from './Footer.module.css';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles['footer-content']}>
        <div className={`${styles.column} ${styles['footer-text']}`}>
        <p>Todos los derechos reservados. <a href="https://www.didactica.com.ec/" target="_blank" rel="noopener noreferrer" className={styles.highlight}>Didáctica Editorial.</a></p>
          <p>Diseño y programación <a href="https://www.relatividadia.com" target="_blank" rel="noopener noreferrer" className={styles.highlight}>Relatividad IA</a></p>
        </div>
        <div className={`${styles.column} ${styles['social-media']}`}>
          <a href="https://www.facebook.com/didacticaEc/" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="https://www.linkedin.com/company/editorial-didactica/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="https://www.youtube.com/@editorialdidactica4477" aria-label="YouTube" target="_blank"><FaYoutube /></a>
          <a href="https://www.instagram.com/didacticaec/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
