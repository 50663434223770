import React, { Component } from 'react';
import styles from './Overlay.module.css';
import Seccion1 from './S1/S1';
import Seccion2 from './S2/S2';
import { resetChat } from '../Seccion1/S1';

class Overlay extends Component {
    constructor(props) {
        super(props);

        const initialUnidades = Array.isArray(props.unidades) ? props.unidades : [];

        this.state = {
            isCheckboxChecked: true,
            isVisible: true,
            totalPreguntas: '',
            unidades: initialUnidades,
            hoverIndex: null,
            responseData: null,
            isRedirecting: false
        };

        this.handleAceptar = this.handleAceptar.bind(this);
        this.toggleUnidadActivo = this.toggleUnidadActivo.bind(this);
        this.toggleTemaActivo = this.toggleTemaActivo.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handlePreguntasChange = this.handlePreguntasChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.extraerInformacion = this.extraerInformacion.bind(this);
        this.temasActivos = this.temasActivos.bind(this);
        this.puedeAceptar = this.puedeAceptar.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    handleMouseEnter() {
        this.setState({ hoverIndex: true });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: false });
    }

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
          return false;
        }
    
        try {
          const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            return true;
          } else {
            localStorage.removeItem('token');
            return false;
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          return false;
        }
      }

    async handleAceptar() {
        const { backendUrlGeneracion, identificador, rama, libro } = this.props;
        const tokenIsValid = await this.verifyToken();
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.")
            return;
        }
        else {
        resetChat();
        this.handleClose();  // Cerrar el overlay inmediatamente
        const dataToSend = {
            "identificador": identificador,
            "libro": libro,
            "queries": this.extraerInformacion(),
            "rama": rama
        };

        fetch(backendUrlGeneracion, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(response => response.json())
        .then(data => {
            this.setState({ responseData: data.response });
            this.props.setResponseData(data.response);  // Pasar responseData a Evaluaciones
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        }
    }

    extraerInformacion() {
        const { isCheckboxChecked, totalPreguntas, unidades } = this.state;
        const resultado = {};
        if (isCheckboxChecked && totalPreguntas) {
            const totalPreguntasNum = parseInt(totalPreguntas, 10);
            let temasActivos = [];
            
            unidades.forEach(unidad => {
                unidad.temas.forEach(tema => {
                    if (tema.activo) {
                        temasActivos.push({ ...tema, unidadId: unidad.id });
                    }
                });
            });
        
            const preguntasPorTema = Math.floor(totalPreguntasNum / temasActivos.length);
            const residuo = totalPreguntasNum % temasActivos.length;
        
            temasActivos.forEach((tema, index) => {
                const preguntasAsignadas = preguntasPorTema + (index < residuo ? 1 : 0);
                const temaIdFormat = tema.id % 100;
                const clave = `${tema.unidadId}.${temaIdFormat.toString().padStart(2, '0')}`;
                resultado[clave] = preguntasAsignadas;
            });
        } else {
            unidades.forEach((unidad) => {
                unidad.temas.forEach((tema) => {
                    if (tema.npreguntas) {
                        const temaIdFormat = tema.id % 100;
                        const clave = `${unidad.id}.${temaIdFormat.toString().padStart(2, '0')}`;
                        const numeroPreguntas = parseInt(tema.npreguntas, 10);
                        if (!isNaN(numeroPreguntas)) {
                            resultado[clave] = numeroPreguntas;
                        }
                    }
                });
            });
        }
        console.log(resultado);
        return resultado;
    }

    toggleUnidadActivo(index) {
        const nuevasUnidades = this.state.unidades.map((unidad, i) =>
            ({ ...unidad, activo: i === index ? !unidad.activo : unidad.activo })
        );
        this.setState({ unidades: nuevasUnidades });
    }

    toggleTemaActivo(idUnidad, idTema) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    ({ ...tema, activo: tema.id === idTema ? !tema.activo : tema.activo })
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
        this.setState({ unidades: nuevasUnidades });
    }

    toggleCheckbox() {
        this.setState(prevState => {
            if (!prevState.isCheckboxChecked) {
                const nuevasUnidades = prevState.unidades.map(unidad => ({
                    ...unidad,
                    temas: unidad.temas.map(tema => ({ ...tema, npreguntas: '' }))
                }));
                return { isCheckboxChecked: !prevState.isCheckboxChecked, unidades: nuevasUnidades };
            }
            return { isCheckboxChecked: !prevState.isCheckboxChecked };
        });
    }

    handlePreguntasChange(idUnidad, idTema, value) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    tema.id === idTema ? { ...tema, npreguntas: value } : tema
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
        this.setState({ unidades: nuevasUnidades });
    }

    handleClose() {
        this.setState({ isVisible: false });
        this.props.onAccept();
    }

    temasActivos() {
        return this.state.unidades.some(unidad => unidad.temas.some(tema => tema.activo));
    }

    puedeAceptar() {
        const { isCheckboxChecked, unidades, totalPreguntas } = this.state;

        const temasSeleccionados = unidades.reduce((acc, unidad) => 
            acc + unidad.temas.filter(tema => tema.activo).length, 0);

        const preguntasTotalesValidas = isCheckboxChecked && totalPreguntas !== '' && !isNaN(Number(totalPreguntas)) && Number(totalPreguntas) > 0 && temasSeleccionados > 0 && Number(totalPreguntas) >= temasSeleccionados;

        const preguntasIndividualesValidas = !isCheckboxChecked && unidades.some(unidad =>
            unidad.temas.some(tema =>
                tema.npreguntas !== '' && !isNaN(Number(tema.npreguntas)) && Number(tema.npreguntas) > 0
            )
        );

        const temasActivos = unidades.some(unidad =>
            unidad.temas.some(tema => tema.activo)
        );

        return (preguntasTotalesValidas && temasActivos) || preguntasIndividualesValidas;
    }

    render() {
        const { isCheckboxChecked, totalPreguntas, unidades, isVisible, hoverIndex, responseData, isRedirecting } = this.state;

        if (isRedirecting) {
            window.location.href = '/login';
            return;
        }

        if (!isVisible) return null;

        const botonAceptarStyle = {
            backgroundColor: hoverIndex ? this.props.botonAceptarColorHover : this.props.botonAceptarColor,
            '--boton-aceptar-color': this.props.botonAceptarColorHover
        };

        return (
            <div className={styles.overlay} style={{ backgroundColor: this.props.modalColor}}>
                <div className={styles.modal} style={{ backgroundColor: this.props.modalColor }}>
                    <form>
                        <Seccion1
                            isCheckboxChecked={isCheckboxChecked}
                            setIsCheckboxChecked={(checked) => this.setState({ isCheckboxChecked: checked })}
                            toggleCheckbox={this.toggleCheckbox}
                            totalPreguntas={totalPreguntas}
                            setTotalPreguntas={(value) => this.setState({ totalPreguntas: value })}
                            unidades={unidades}
                            onToggleUnidad={this.toggleUnidadActivo}
                            optionGroupColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                            responseData={responseData} // Pasar responseData a Seccion1
                        />
                        <Seccion2
                            isCheckboxChecked={isCheckboxChecked}
                            toggleTemaActivo={this.toggleTemaActivo}
                            unidades={unidades}
                            handlePreguntasChange={this.handlePreguntasChange}
                            containerColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                        />
                        <button
                            type="button"
                            onClick={this.handleAceptar}
                            disabled={!this.puedeAceptar()}
                            className={`${styles.acceptButton} ${!this.puedeAceptar() ? styles.buttonDisabled : ''}`}
                            style={botonAceptarStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            ACEPTAR
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Overlay;
