import React from 'react';
import VerifyHeader from './Header/VerifyHeader';
import VerifyMainContent from './MainContent/VerifyMainContent';
import Footer from '../LandingPage/Footer/Footer'
import styles from './Verify.module.css';

const Verify = () => {
  return (
    <div className={styles.verifyContainer}>
        <VerifyHeader />
        <VerifyMainContent />
        <Footer/>
    </div>
  );
};

export default Verify;
