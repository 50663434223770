import React from 'react';
import LoginHeader from './Header/LoginHeader';
import LoginMainContent from './MainContent/LoginMainContent';
import LoginFooter from '../LandingPage/Footer/Footer';
import styles from './LoginPage.module.css';

const LoginPage = () => {
    return (
        <div className={styles.loginPage}>
            <LoginHeader />
            <LoginMainContent />
            <LoginFooter />
        </div>
    );
}

export default LoginPage;
