import React from 'react';
import styles from './Terminos.module.css';

const Terminos = () => {
    const handleCopyEmail = () => {
        navigator.clipboard.writeText('kevin@relatividadia.com');
        alert('Correo copiado al portapapeles.');
    };

    return (
        <div className={styles.container}>
            <div className={styles.tituloterminos}>
                Política General de Protección de Datos
            </div>

            <div className={styles.pterminos}>
                <strong>RELATIVIDAD IA</strong>
            </div>

            <div className={styles.pterminos}>
                <strong>CONTENIDO</strong>
            </div>

            <div className={styles.pterminos}>
                POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES <br />
                CAPÍTULO I: OBJETO ÁMBITO Y GLOSARIO DE TÉRMINOS <br />
                CAPÍTULO II: DEL TRATAMIENTO DE DATOS PERSONALES <br />
                CAPÍTULO III: DE LOS DATOS PERSONALES Y SU CICLO VITAL <br />
                CAPÍTULO IV: DE LA SEGURIDAD DE LOS DATOS PERSONALES <br />
                DISPOSICIÓN GENERAL <br />
                DISPOSICIONES TRANSITORIAS
            </div>

            <div className={styles.pterminos}>
                LA JUNTA DE ACCIONISTAS RELATIVIDAD IA SOLUCIONES CON INTELIGENCIA ARTIFICIAL S.A.S.
            </div>

            <div className={styles.pterminos}>
                CONSIDERANDO
            </div>

            <div className={styles.pterminos}>
                Que el artículo 66 de la Constitución de la República reconoce y garantiza a las personas el acceso y la decisión sobre información y datos de este carácter, así como su correspondiente protección. La recolección, archivo, procesamiento, distribución o difusión de estos datos o información requerirán la autorización del titular o el mandato de la ley;
            </div>

            <div className={styles.pterminos}>
                Que la Constitución ecuatoriana reconoce en su artículo 355 la autonomía de las universidades y escuelas politécnicas que debe ser ejercida de manera solidaria y responsable, garantizando el ejercicio de la libertad académica y el derecho a la búsqueda de la verdad sin restricciones;
            </div>

            <div className={styles.pterminos}>
                Que el artículo 39 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, reconoce el acceso universal libre y seguro a entornos digitales, señalando que este acceso es un derecho de los ciudadanos;
            </div>

            <div className={styles.pterminos}>
                Responsable de Tratamiento: <strong>RELATIVIDAD IA SOLUCIONES CON INTELIGENCIA ARTIFICIAL S.A.S.</strong>
            </div>

            <div className={styles.pterminos}>
                Número de RUC: 1793215455001 <br />
                Dirección: Pomasqui Quito Conjunto SIRAH 1 <br />
                Número de teléfono: 0995772424 <br />
                Correo electrónico: 
                <span className={styles.email} onClick={handleCopyEmail}>
                    kevin@relatividadia.com
                </span>
            </div>

            <div className={styles.pterminos}>
                <strong>CAPÍTULO I: OBJETO ÁMBITO Y GLOSARIO DE TÉRMINOS</strong>
            </div>

            <div className={styles.pterminos}>
                Artículo 1.- Objeto. Esta política tiene como objeto proteger los datos personales de todos los miembros de la compañía, incluyendo empleados, clientes, proveedores, y terceros.
            </div>

            <div className={styles.pterminos}>
                Artículo 2.- Ámbito de aplicación. Esta política se aplica al tratamiento de los datos de carácter personal de RELATIVIDAD IA, tanto a nivel nacional como internacional.
            </div>

            <div className={styles.pterminos}>
                Artículo 3.- Glosario de términos y abreviaturas. A efectos de la presente política se tomarán en consideración las siguientes definiciones: <br />
                - Empleados: persona la cual colabora con RELATIVIDAD IA en el campo asignado de acorde a sus capacidades.<br />
                - Anonimización: Procedimiento que consiste en la aplicación de medidas dirigidas a impedir la identificación o re-identificación de una persona natural sin esfuerzos desproporcionados.<br />
                - Base de datos o ficheros: Conjunto de información perteneciente a un mismo contexto, ordenada de modo sistemático para su análisis, almacenamiento, organización, recuperación o transmisión.<br />
                - Cookies: Archivos de información que sirven de identificador y se almacenan en el ordenador de un usuario de un sitio web. A menudo se utilizan para gestionar preferencias del usuario y para personalización de los sitios web.<br />
                - Cliente: persona la cual se beneficia de un producto o servicio de RELATIVIDAD IA previa compra.<br />
                - Consentimiento: Manifestación de la voluntad libre, específica, informada e inequívoca por el que el titular de los datos personales autoriza al responsable del tratamiento de los datos personales a tratar los mismos.<br />
                - Dato biométrico: Dato personal único, relativo a las características físicas o fisiológicas, o conductas de una persona natural que permita o confirme la identificación única de dicha persona, como imágenes faciales o datos dactiloscópicos, entre otros.<br />
                - Datos de acceso a sistemas: Datos relativos a usuarios y contraseñas para ingreso a plataformas e interfaces.<br />
                - Datos de navegación: Datos relativos al historial de navegación en páginas web de un usuario con sus credenciales. o con los equipos de la RELATIVIDAD IA.<br />
                - Datos personales: Datos que identifican o hacen identificable a una persona natural, directa o indirectamente.<br />
                - Datos personales crediticios: Datos que integran el comportamiento económico de personas naturales, para analizar su capacidad financiera.<br />
                - Datos de salud: datos personales relativos a la salud física o mental de una persona, incluida la prestación de servicios de atención sanitaria, que revelen información sobre su estado de salud.<br />
                - Datos sensibles: Datos relativos a etnia, identidad de género, identidad cultural, religión, ideología, filiación política, pasado judicial, condición migratoria, orientación sexual, sexo biológico, salud, datos biométricos, datos genéticos y aquellos cuyo tratamiento indebido pueda dar origen a discriminación, atenten o puedan atentar contra los derechos y libertades fundamentales.<br />
                - Encargado del tratamiento de datos personales: Persona natural o jurídica, pública o privada, autoridad pública, u otro organismo que solo o juntamente con otros trate datos personales a nombre y por cuenta de un responsable de tratamiento de datos personales.<br />
                - Proveedor: persona que brinda un servicio o producto el cual sea requerido por RELATIVIDAD IA.<br />
                - Ley Orgánica de Protección de Datos Personales: Ley que norma el tratamiento de datos personales realizado por empresas públicas y privadas, a fin de garantizar el derecho constitucional de protección de datos personales, su abreviatura es LOPDP.<br />
                - Medidas o controles: Se entiende por tales aquellas aceptadas por el estado de la técnica, sean estas organizativas, técnicas o de cualquier otra índole.<br />
                - Protección de datos: Es el derecho a la protección de datos de carácter personal, que incluye el acceso y la decisión sobre información y datos de tal carácter, así como su correspondiente protección.<br />
                - Responsable de tratamiento de datos personales: persona natural o jurídica, pública o privada, autoridad pública, u otro organismo, que solo o conjuntamente con otros decide sobre la finalidad y el tratamiento de datos personales.<br />
                - Seguridad de los datos personales: seguridad de la información en el ámbito específico de información clasificada como datos personales.<br />
                - Terceros: Se entiende como terceros a practicantes, aspirantes, contratistas, jubilados y, en general, cualquier persona natural o jurídica que mantenga una relación con RELATIVIDAD IA.<br />
                - Titulares de datos personales: Persona natural cuyos datos son objeto de tratamiento.<br />
                - Tratamiento: Cualquier operación o conjunto de operaciones realizadas sobre datos personales, ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizado, tales como: la recolección, recopilación, obtención, registro, organización, estructuración, conservación, custodia, adaptación, modificación, eliminación, indexación, extracción, consulta, elaboración, utilización, posesión, aprovechamiento, distribución, cesión, comunicación o transferencia, o cualquier otra forma de habilitación de acceso, cotejo, interconexión, limitación, supresión, destrucción y, en general, cualquier uso de datos personales.<br />
                - Vulneración de la seguridad de los datos personales: Incidente de seguridad que afecta la confidencialidad, disponibilidad o integridad de los datos personales.
            </div>

            <div className={styles.pterminos}>
                <strong>CAPÍTULO II: DEL TRATAMIENTO DE DATOS PERSONALES</strong>
            </div>

            <div className={styles.pterminos}>
                Artículo 4.- Identificación del responsable de Tratamiento. De conformidad con lo dispuesto en la LOPDP se desprende la siguiente información:<br />
                Responsable de Tratamiento: RELATIVIDAD IA SOLUCIONES CON INTELIGENCIA ARTIFICIAL S.A.S. (en adelante “RELATIVIDAD IA” o el “Responsable del Tratamiento”)<br />
                Número de RUC: 1793215455001<br />
                Dirección: Pomasqui Quito Conjunto SIRAH 1<br />
                Número de teléfono: 0995772424<br />
                Correo electrónico: <span className={styles.email} onClick={handleCopyEmail}>kevin@relatividadia.com</span>
            </div>

            <div className={styles.pterminos}>
                Artículo 5.- Tipos de tratamiento. RELATIVIDAD IA podrá realizar cualquier operación o conjunto de operaciones realizadas sobre datos personales ya sea por procedimientos técnicos de carácter automatizado, parcialmente automatizado o no automatizado, tales como: recolección, recopilación, obtención, registro, organización, estructuración, conservación, custodia, adaptación, modificación, eliminación, indexación, extracción, consulta, elaboración, utilización, posesión, aprovechamiento, distribución, cesión, comunicación o transferencia, o cualquier otra forma de habilitación de acceso, cotejo, interconexión, limitación, supresión, destrucción y, en general, cualquier uso de datos personales de los titulares.
            </div>

            <div className={styles.pterminos}>
                Artículo 6.- Datos que se recolectan. RELATIVIDAD IA a través de sus sistemas de procesamiento de información recolecta legítimamente en forma física o digital la siguiente información:<br />
                - Datos biométricos;<br />
                - Datos crediticios;<br />
                - Datos personales;<br />
                - Datos de acceso a sistemas;<br />
                - Datos de navegación;<br />
                - Categorías especiales de datos y datos sensibles: datos de niñas, niños y adolescentes, datos de salud y datos de personas con discapacidad y de sus sustitutos relativos a la discapacidad.
            </div>

            <div className={styles.pterminos}>
                <strong>CAPÍTULO III: DE LOS DATOS PERSONALES Y SU CICLO VITAL</strong>
            </div>

            <div className={styles.pterminos}>
                Artículo 11.- Origen de los datos personales. Los datos personales pueden ser obtenidos directamente del titular. Adicionalmente, se podrán obtener a través de representantes legales, terceros debidamente autorizados, registros públicos de consulta o cámaras de vigilancia.
            </div>

            <div className={styles.pterminos}>
                Artículo 12.- Transferencia nacional e internacional de datos. RELATIVIDAD IA puede transferir datos personales de los titulares tanto a nivel nacional como internacional a instituciones o individuos con los cuales RELATIVIDAD IA tenga acuerdos contractuales. Estas transferencias tienen como objetivo proporcionar y mejorar los servicios, asegurar el correcto funcionamiento de los contratos y administrar procesos.
            </div>

            <div className={styles.pterminos}>
                Artículo 13.- Entrega o negativa de entrega de datos personales. Cuando el titular o su representante legal autorizado proporcione sus datos personales a RELATIVIDAD IA, esta se compromete a que estos datos sean tratados de acuerdo con la presente Política General y las leyes de protección de datos vigentes.
            </div>

            <div className={styles.pterminos}>
                Artículo 14.- Efecto de suministrar datos personales inexactos o erróneos. Si los datos personales entregados son inexactos o erróneos, el titular de los mismos o su representante legal se responsabiliza y acepta que se pudiese generar un inadecuado tratamiento de ellos o un funcionamiento incorrecto de los servicios que presta RELATIVIDAD IA.
            </div>

            <div className={styles.pterminos}>
                <strong>CAPÍTULO IV: DE LA SEGURIDAD DE LOS DATOS PERSONALES</strong>
            </div>

            <div className={styles.pterminos}>
                Artículo 17.- Medidas para precautelar la seguridad de los datos personales. RELATIVIDAD IA adoptará mecanismos que permitan precautelar la seguridad de los datos personales a fin de evitar su alteración, pérdida, tratamiento o acceso no autorizado tomando en consideración la naturaleza de la información y los riesgos a los que se encuentran expuestos.
            </div>

            <div className={styles.pterminos}>
                Artículo 18.- Acuerdos de confidencialidad. Es obligatoria la suscripción de Acuerdos de Confidencialidad para todos quienes requieran acceso a información institucional que contenga datos personales, datos sensibles e información confidencial.
            </div>

            <div className={styles.pterminos}>
                Artículo 19.- Tratamiento de datos de niños, niñas y adolescentes. En caso de que RELATIVIDAD IA, en el cumplimiento de sus actividades, obtenga datos personales de los niños, niñas y adolescentes, su tratamiento se lo realizará de conformidad a lo determinado en artículo 21 de la LOPDP.
            </div>

            <div className={styles.pterminos}>
                <strong>DISPOSICIÓN GENERAL</strong>
            </div>

            <div className={styles.pterminos}>
                PRIMERA. – RELATIVIDAD IA podrá actualizar su Política General de Protección de Datos Personales cuando considere necesario los que entrarán en vigor a partir de su publicación en la “PÁGINA OFICIAL DE RELATIVIDAD IA” para conocimiento de los miembros de la compañía y todos los que se encuentren relacionado con la misma.
            </div>

            <div className={styles.pterminos}>
                <strong>DISPOSICIONES TRANSITORIAS</strong>
            </div>

            <div className={styles.pterminos}>
                PRIMERA. - La presente política entrará en vigor a partir de su publicación en la “PÁGINA OFICIAL DE RELATIVIDAD IA”.
            </div>

            <div className={styles.pterminos}>
                SEGUNDA. – Una vez que se encuentre vigente esta política, la Dirección de Informática conjuntamente con la Dirección de Comunicación Estratégica implementarán los correspondientes banners de cookies y publicarán los términos y condiciones de uso en todos los portales institucionales.
            </div>

            <div className={styles.pterminos}>
                CERTIFICACIÓN. - En mi calidad de Gerente General certifico que la presente Política General de Protección de Datos Personales fue analizada y aprobada en sesión ordinaria llevada a cabo el miércoles 04 de agosto de 2024.
            </div>

            <div className={styles.pterminos}>
                Kevin Rojas <br />
                Gerente General RELATIVIDAD IA
            </div>
        </div>
    );
};

export default Terminos;
