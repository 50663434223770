import React, { Component } from 'react';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import styles from './S1.module.css';
import Imagen1 from './images/aster.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function downloadFile(base64Data, fileName, mimeType) {
    const binaryString = window.atob(base64Data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: mimeType });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(link.href);
}

let listaDeMensajesBack = [{"role":"system", "content":"prompt"}, {"role":"assistant", "content":"¡Hola! Soy Aster, asistente docente de Didáctica Editorial. Estoy listo para ayudarte a crear una planificación. Generaremos los campos en el siguiente orden: Experiencia, reflexión, contenidos, actividades de aplicación y finalmente, actividades de evaluación. ¿Deseas iniciar el proceso?"}]
let chatIDFuncional = 3;

const resetChat = () => {
    chatIDFuncional = 3;
    listaDeMensajesBack = [{"role":"system", "content":"prompt"}, {"role":"assistant", "content":"¡Hola! Soy Aster, asistente docente de Didáctica Editorial. Estoy listo para ayudarte a crear una planificación. Generaremos los campos en el siguiente orden: Experiencia, reflexión, contenidos, actividades de aplicación y finalmente, actividades de evaluación. ¿Deseas iniciar el proceso?"}];
};

class Seccion1Chat extends Component {
    constructor(props) {
        super(props);
        
        const { responseData } = this.props;
        
        this.state = {
            messages: responseData ? [{ role: "system", content: responseData }] : [{ role: "system", content: "¡Hola! Soy Aster, asistente docente de Didáctica Editorial. Estoy listo para ayudarte a crear una planificación. Generaremos los campos en el siguiente orden: Experiencia, reflexión, contenidos, actividades de aplicación y finalmente, actividades de evaluación. ¿Deseas iniciar el proceso?" }],
            inputMessage: "",
            isSending: false,
            isTyping: false,
            docFile: null,
            pdfFile: null,
            isChatActive: true,
            sendButtonHover: false,
            btndocxHover: false,
            isRedirecting: false
        };

        this.sendMessage = this.sendMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleDocxClick = this.handleDocxClick.bind(this);
        this.handlePdfClick = this.handlePdfClick.bind(this);
        this.handleSendButtonMouseEnter = this.handleSendButtonMouseEnter.bind(this);
        this.handleSendButtonMouseLeave = this.handleSendButtonMouseLeave.bind(this);
        this.handleDocxMouseEnter = this.handleDocxMouseEnter.bind(this);
        this.handleDocxMouseLeave = this.handleDocxMouseLeave.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    handleSendButtonMouseEnter() {
        this.setState({ sendButtonHover: true });
    }

    handleSendButtonMouseLeave() {
        this.setState({ sendButtonHover: false });
    }

    handleDocxMouseEnter() {
        this.setState({ btndocxHover: true });
    }

    handleDocxMouseLeave() {
        this.setState({ btndocxHover: false });
    }

    componentDidMount() {
        const { responseData, temasTitulos } = this.props;
        if (responseData) {
            this.setState(prevState => ({
                messages: [...prevState.messages, { role: "system", content: `El tema de nuestra planificación es ${responseData} ${temasTitulos[responseData]} ¿Deseas iniciar el proceso?` }]
            }));
        }
    }

    scrollToBottom() {
        const chatMessages = document.querySelector(`.${styles.chatmessages}`);
        if (chatMessages) {
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.messages.length !== this.state.messages.length) {
            this.scrollToBottom();
        }
    }

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        
        try {
            const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                return true;
            } else {
                localStorage.removeItem('token');
                return false;
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('token');
            return false;
        }
    }
    
    async sendMessage() {
        const { inputMessage } = this.state;
        const { responseData, backendUrl, rama, libro, identificador } = this.props;
        const trimmedMessage = inputMessage.trim();
        if (!trimmedMessage) return;

        const tokenIsValid = await this.verifyToken();
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.")
            return;
        }
    
        const newMessage = { role: "user", content: trimmedMessage };
        listaDeMensajesBack.push({ role: "user", content: trimmedMessage });
        this.setState(prevState => ({
            messages: [...prevState.messages, newMessage],
            inputMessage: '',
            isSending: true,
            isTyping: true,
            isChatActive: false
        }), this.scrollToBottom);
    
        let dataToSend = {
            messages: listaDeMensajesBack,
            identificador: identificador,
            libro: libro,
            chatID: chatIDFuncional,
            unidadTema: responseData,
            rama: rama
        };
    
        fetch('https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/planificaciones', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                isSending: false,
                isTyping: false,
                isChatActive: true,
                messages: data.response && !prevState.messages.find(m => m.content === data.response) 
                    ? [...prevState.messages, { role: "assistant", content: data.response }]
                    : prevState.messages
            }), this.scrollToBottom);
    
            if (data.response) {
                listaDeMensajesBack = data.messages;
            }
    
            if (data.img64) {
                const imageUrl = `data:image/png;base64,${data.img64}`;
                const imageMessage = { role: "assistant", content: "", image: imageUrl };
                this.setState(prevState => ({
                    messages: [...prevState.messages, imageMessage]
                }));
            }
    
            chatIDFuncional = data.chatID;
            if (data.doc) {
                this.setState({ docFile: data.doc });
            }
            if (data.pdf) {
                this.setState({ pdfFile: data.pdf });
            }
        })
        .catch(error => {
            this.setState({
                isSending: false,
                isTyping: false,
                isChatActive: true
            });
            console.error('Error:', error);
        });
    }    

    handleInputChange(e) {
        this.setState({ inputMessage: e.target.value });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
        }
    }

    handleDocxClick() {
        const { docFile } = this.state;
        if (docFile) {
            try {
                downloadFile(docFile, 'PlanificacionCurricular.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            } catch (error) {
                console.error('Error al procesar el archivo:', error);
                alert('Error al procesar el archivo para descargar.');
            }
        } else {
            alert("No hay un documento disponible para descargar en este momento. Por favor, intenta de nuevo más tarde.");
        }
    }

    handlePdfClick() {
        const { pdfFile } = this.state;
        if (pdfFile) {
            try {
                downloadFile(pdfFile, 'PlanificacionCurricular.pdf', 'application/pdf');
            } catch (error) {
                console.error('Error al procesar el archivo:', error);
                alert('Error al procesar el archivo para descargar.');
            }
        } else {
            alert("No hay un documento disponible para descargar en este momento. Por favor, intenta de nuevo más tarde.");
        }
    }

    renderMessageContent(message) {
        const parts = message.split(/(\$[\s\S]*?\$)/g);
        return parts.map((part, index) => {
            if (part.startsWith('$') && part.endsWith('$')) {
                return <BlockMath key={index}>{part.slice(1, -1)}</BlockMath>;
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    }

    render() {
        const { messages, inputMessage, isSending, isTyping, isChatActive, sendButtonHover, btndocxHover } = this.state;
        const { chatContainerColor, chatHeaderColor, chatFormColor, userBubbleColor, assistantBubbleColor, headerTextColor, sendButtonColor, sendButtonHoverColor, fondoDelChat, textAreaBorder, btndocxColor, btndocxColorHover } = this.props;

        const messagesStyle = { backgroundColor: fondoDelChat };
        const chatContainerStyle = { backgroundColor: chatContainerColor };
        const chatHeaderStyle = { backgroundColor: chatHeaderColor };
        const headerTextStyle = { color: headerTextColor };
        const chatFormStyle = { backgroundColor: chatFormColor };
        const userBubbleStyle = { backgroundColor: userBubbleColor };
        const assistantBubbleStyle = { backgroundColor: assistantBubbleColor };
        const sendButtonStyle = { backgroundColor: sendButtonHover ? sendButtonHoverColor : sendButtonColor };
        const textAreaStyle = { border: textAreaBorder };
        const btndocxStyle = { backgroundColor: btndocxHover ? btndocxColorHover : btndocxColor };

        if (this.state.isRedirecting) {
            window.location.href = '/login';
            return;
        }

        return (
            <div className={styles.container}>
                <div className={styles.rightRectangle}>
                    <div className="custom-section">
                        <div className={styles.chatcontainer} style={chatContainerStyle}>
                            <div className={styles.chatheader} style={chatHeaderStyle}>
                                <div className={styles.headercontent}>
                                    <div className={styles.profilepicture}>
                                        <img src={Imagen1} alt="Profile" />
                                    </div>
                                    <h2 style={headerTextStyle}>Aster asistente docente</h2>
                                    <div className={styles.headerButtons}>
                                        <button 
                                            className={`${styles.btndocx} ${!this.state.docFile ? styles.buttonDisabled : ''}`} 
                                            style={btndocxStyle} 
                                            onClick={this.handleDocxClick} 
                                            onMouseEnter={this.handleDocxMouseEnter} 
                                            onMouseLeave={this.handleDocxMouseLeave}
                                            disabled={!this.state.docFile}
                                        >
                                            Descargar en docx
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.chatmessages} style={messagesStyle}>
                                {messages.map((message, index) => (
                                    <div 
                                        key={index} 
                                        className={`${styles.messagebubble} ${message.role === "user" ? styles.user : styles.assistant}`} 
                                        style={message.role === "user" ? userBubbleStyle : assistantBubbleStyle}
                                    >
                                        {message.image ? (
                                            <img src={message.image} alt="Fetched from backend" className={styles.scaledImage} />
                                        ) : (
                                            this.renderMessageContent(message.content)
                                        )}
                                    </div>
                                ))}
                                {isTyping && <div className={`${styles.messagebubble} ${styles.typing}`}>Escribiendo...</div>}
                            </div>
                            <form 
                                className={styles.chatform} 
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    this.sendMessage();
                                }} 
                                style={chatFormStyle}
                            >
                                <textarea 
                                    className={styles.formcontrol}
                                    value={inputMessage}
                                    onChange={this.handleInputChange}
                                    placeholder="Escríbeme tu mensaje"
                                    required
                                    rows="1"
                                    onKeyDown={this.handleKeyDown}
                                    disabled={!isChatActive}
                                    style={textAreaStyle}
                                ></textarea>
                                <button 
                                    type="submit" 
                                    className={styles.btnsend} 
                                    style={sendButtonStyle} 
                                    disabled={!isChatActive || isSending} 
                                    onMouseEnter={this.handleSendButtonMouseEnter} 
                                    onMouseLeave={this.handleSendButtonMouseLeave}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'white' }} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seccion1Chat;
export { resetChat };
