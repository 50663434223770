import React, { useState } from 'react';
import ReportBugOverlay from './ReportBugOverlay';
import styles from './Button.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importa los estilos de Font Awesome

const verifyToken = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      return true;
    } else {
      localStorage.removeItem('token');
      return false;
    }
  } catch (error) {
    console.error('Error verifying token:', error);
    localStorage.removeItem('token');
    return false;
  }
};

const ReportBugButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = async () => {
    const tokenIsValid = await verifyToken();
    if (!tokenIsValid) {
      alert("Token caducado. Vuelve a iniciar sesión.");
      window.location.href = '/login';
      return;
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <button className={styles.button} onClick={handleButtonClick}>
        <span className={styles.buttonIcon}><i className="fas fa-bug"></i></span>
        <span className={styles.buttonText}>Reportar error</span>
      </button>
      {isOpen && <ReportBugOverlay onClose={handleClose} />}
    </div>
  );
};

export default ReportBugButton;
