import React, { useState, useContext } from 'react';
import axios from 'axios';
import styles from './CodeOverlay.module.css';
import AsterCodigo from './images/AsterCodigo.png';
import { AuthContext } from '../../AuthContext/AuthContext';

const CodeOverlay = ({ onClose }) => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { user, isAuthenticated, loading } = useContext(AuthContext);

  const handleSubmit = async () => {
    if (!isAuthenticated) {
      setMessage('No has iniciado sesión');
      return;
    }

    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 5000);

    try {
      const response = await axios.post(`https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/professors/${user.id}/add-book`, { codigo: code }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message || 'Error al añadir el libro');
    }
  };

  const handleOverlayClick = (e) => {
    // Si el click ocurre en el fondo del overlay, cierra el overlay
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (loading) {
    return <div className={styles.overlay}><div className={styles.loading}>Cargando...</div></div>;
  }

  if (!isAuthenticated) {
    return <div className={styles.overlay}><div className={styles.notAuthenticated}>No has iniciado sesión</div></div>;
  }

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.overlayContent}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <div className={styles.formContainer}>
          <div className={styles.heading}>Ingresa tu código del libro</div>
          <div className={styles.inputContainer}>
            <input 
              type="text" 
              value={code} 
              onChange={(e) => setCode(e.target.value)} 
              onKeyPress={handleKeyPress}
              required 
              className={styles.textInput}
            />
            <button onClick={handleSubmit} className={styles.submitButton} disabled={isButtonDisabled}>Ingresar</button>
          </div>
          {message && <p className={styles.message}>{message}</p>}
        </div>
        <div className={styles.imageContainer}>
          <img src={AsterCodigo} alt="Aster Codigo" className={styles.asterImage} />
        </div>
      </div>
    </div>
  );
};

export default CodeOverlay;
