import React, { Component } from "react";
import WholeContent from "./MainContent/MainContent";
import ReportBugButton from "../Report/ReportBugButton";
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext/AuthContext'; // Importar AuthContext

class HomeLibro extends Component {
  static contextType = AuthContext; // Usar el contexto de autenticación

  state = {
    hasAccess: true,
    loading: true,
    error: null // Añadir estado de error
  };

  componentDidMount() {
    const { id: userId } = this.context.user;
    const { id: bookId } = this.props.params; // Cambiado a this.props.params
    
    axios.get(`https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/check-access/${userId}/${bookId}`)
      .then(response => {
        if (response.data.access) {
          this.setState({ hasAccess: true, loading: false });
        } else {
          this.setState({ hasAccess: false, loading: false });
        }
      })
      .catch(error => {
        console.error('Error checking access', error);
        this.setState({ hasAccess: false, loading: false, error: error.response ? error.response.status : 'Unknown error' });
      });
  }

  render() {
    const {
      backgroundImage,
      logoImage,
      asterImage,
      chatButtonColor,
      planificacionesButtonColor,
      evaluacionesButtonColor,
      socialButtonColor,
      welcomeText,
      libroTitulo
    } = this.props;

    if (this.state.loading) {
      return <div>Cargando...</div>;
    }

    if (this.state.error === 403) {
      return <Navigate to="/libros/not-found" />;
    }

    if (!this.state.hasAccess) {
      return <Navigate to="/libros/not-found" />;
    }

    return (
      <div>
        <WholeContent
          libroTitulo={libroTitulo}
          backgroundImage={backgroundImage}
          logoImage={logoImage}
          asterImage={asterImage}
          welcomeText={welcomeText}
          chatButtonColor={chatButtonColor}
          planificacionesButtonColor={planificacionesButtonColor}
          evaluacionesButtonColor={evaluacionesButtonColor}
          socialButtonColor={socialButtonColor}
        />
        <ReportBugButton/>
      </div>
    );
  }
}

const HomeLibroWrapper = (props) => {
  const params = useParams();
  return <HomeLibro {...props} params={params} />;
};

export default HomeLibroWrapper;
