import React, { Component, useState } from 'react';
import Seccion1 from './Seccion1/S1'; // Asegúrate de que la ruta de importación sea correcta
import Volver from './Volver/Volver';
import Overlay from './Overlay/Overlay';
import ReportBugButton from "../Report/ReportBugButton";


class Chat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOverlayVisible: true,
            responseData: null,
        }

        this.handleAccept = this.handleAccept.bind(this);
        this.setResponseData = this.setResponseData.bind(this);
    }

    handleAccept() {
        this.setState({ isOverlayVisible: false });
    }

    setResponseData(data) {
        this.setState({ responseData: data });
    }

    render() {
        const { isOverlayVisible, responseData } = this.state;

        return (
            <div>  
                <Volver isVisible={true} titulo={this.props.libroExtendido} />
                {isOverlayVisible && (
                    <Overlay 

                        unidades={this.props.unidades}
                        isVisible={isOverlayVisible} 
                        setResponseData={this.setResponseData} 
                        onAccept={this.handleAccept}
                        modalColor={this.props.modalColor}            
                        generalBackgroundColor={this.props.generalBackgroundColor} //color interior
                        checkmarkColor={this.props.checkmarkColor}
                        checkmarkBorderColor={this.props.checkmarkBorderColor}
                        checkmarkHoverColor={this.props.checkmarkHoverColor}
                        checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                        scrollbarTrackColor={this.props.modalColor}
                        scrollbarThumbColor={this.props.generalBackgroundColor}
                        scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                        botonAceptarColor={this.props.botonAceptarColor}
                        botonAceptarColorHover={this.props.botonAceptarColorHover}
                    />
                )}
                <Seccion1 
                    identificador = {this.props.identificador}
                    libro =  {this.props.libro}
                    rama = {this.props.rama}
                    responseData={responseData}
                    containerColor={this.props.colorExterior} //Este es el color del fondo que esta el chat
                    chatContainerColor={this.props.modalColor} //ESTE ES COLOR DEL FONDO DE los msjs
                    chatHeaderColor={this.props.generalBackgroundColor}
                    chatFormColor={this.props.generalBackgroundColor} //Color de abajo
                    userBubbleColor={this.props.userBubbleColor}
                    assistantBubbleColor={this.props.assistantBubbleColor}
                    sendButtonColor={this.props.sendButtonColor}
                    sendButtonHoverColor={this.props.sendButtonHoverColor}
                    headerTextColor={this.props.colorTituloChat}
                    backendUrl={this.props.linkParaHablarConElChat}
                    temasTitulos={this.props.temasTitulos} 
                    libroExtendido = {this.props.libroExtendido}
                />
                <ReportBugButton/>
            </div>
        );
    }
}

export default Chat;
/* const Chat = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [responseData, setResponseData] = useState(null);

  const handleAccept = () => {
    setIsOverlayVisible(false);
  };

  
}
*/


