import axios from 'axios';

const setupAxiosInterceptors = (logoutCallback) => {
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                // Token expirado o no válido, eliminarlo del almacenamiento local
                localStorage.removeItem('token');
                // Llamar al callback de logout para manejar el estado en la aplicación
                logoutCallback();
            }
            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
