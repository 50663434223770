import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage/LoginPage';
import LandingPage from './LandingPage/LandingPage';
import RegisterPage from './RegisterPage/RegisterPage';
import Home from './Home/Home';
import Verify from './Verify/Verify';
import PasswordReset from './PasswordReset/PasswordReset';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import MailParaContraseña from './MailParaContraseña/MailParaContraseña';
import Mediador from './elMediador';
import NotFound from './NotFound/MainContent';
import AuthRedirect from './AuthContext/AuthRedirect';
import Terminos from './TerminosYCondiciones/Terminos';

const App = () => {

  console.log('Renderizando App');
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route 
            path="/libros/:id/*" 
            element={
              <Mediador identificador="43214" welcomeText="¡Bienvenido Diego!"/>
            } 
          />
          <Route exact path="/login" element={<AuthRedirect> <LoginPage /> </AuthRedirect>} />
          <Route exact path="/register" element={<AuthRedirect> <RegisterPage /> </AuthRedirect>} />
          <Route exact path="/verify" element={<Verify />} />
          <Route exact path="/password-reset" element={ <AuthRedirect> <PasswordReset /> </AuthRedirect>} />
          <Route exact path="/mail-contraseña" element={<MailParaContraseña />} />
          <Route exact path="/terms-and-conditions" element={<Terminos />} />
          <Route
            path="/home"
            element={
              <ProtectedRoute element={Home} />
            }
          />
          <Route 
            path="/libros/:id/*" 
            element={
              <ProtectedRoute element={Mediador} />
            } 
          />
          <Route 
            path="/libros/not-found"
            element={
              <ProtectedRoute element={NotFound} />
            }
            />
        </Routes>
      </Router>
  );
};

export default App;
