import React from 'react';
import Header from '../Verify/Header/VerifyHeader'
import MailParaContraseñaMainContent from './MainContent/MailParaContraseñaMainContent';
import Footer from '../LandingPage/Footer/Footer'

const MailParaContraseña = () => {
  return (
    <div>
        <Header/>
        <MailParaContraseñaMainContent />
        <Footer/>
    </div>
  );
};

export default MailParaContraseña;
