import fondoAzul from './HomeLibro/MainContent/images/FondoHomeLibro_Azul.png';
import fondoVerde from './HomeLibro/MainContent/images/FondoHomeLibro_Verde.png';
import fondoRosa from './HomeLibro/MainContent/images/FondoHomeLibro_Rosa.png';
import fondoMorado from './HomeLibro/MainContent/images/FondoHomeLibro_Morado.png';
import fondoNaranja from './HomeLibro/MainContent/images/FondoHomeLibro_Naranja.png';
import fondoRojo from './HomeLibro/MainContent/images/FondoHomeLibro_Rojo.png';

let libros = {
  1: "BIO1BGU",
  2: "BIO2BGU",
  3: "BIO3BGU",
  4: "ECA1BGU",
  5: "ECA2BGU",
  6: "ESCI1BGU",
  7: "ESCI2BGU",
  8: "EMPRE1BGU",
  9: "EMPRE2BGU",
  10: "EMPRE3BGU",
  11: "FILO1BGU",
  12: "FILO2BGU",
  13: "FIS1BGU",
  14: "FIS2BGU",
  15: "FIS3BGU",
  16: "HISTO1BGU",
  17: "HISTO2BGU",
  18: "HISTO3BGU",
  19: "LYL1BGU",
  20: "LYL2BGU",
  21: "LYL3BGU",
  22: "MATE1BGU",
  23: "MATE2BGU",
  24: "MATE3BGU",
  25: "QUIM1BGU",
  26: "QUIM2BGU",
  27: "QUIM3BGU",
  28: "CN10EGB",
  29: "CN2EGB",
  30: "CN3EGB",
  31: "CN4EGB",
  32: "CN5EGB",
  33: "CN6EGB",
  34: "CN7EGB",
  35: "CN8EGB",
  36: "CN9EGB",
  37: "ES10EGB",
  38: "ES2EGB",
  39: "ES3EGB",
  40: "ES4EGB",
  41: "ES5EGB",
  42: "ES6EGB",
  43: "ES7EGB",
  44: "ES8EGB",
  45: "ES9EGB",
  46: "LYL10EGB",
  47: "LYL2EGB",
  48: "LYL3EGB",
  49: "LYL4EGB",
  50: "LYL5EGB",
  51: "LYL6EGB",
  52: "LYL7EGB",
  53: "LYL8EGB",
  54: "LYL9EGB",
  55: "MATE10EGB",
  56: "MATE2EGB",
  57: "MATE3EGB",
  58: "MATE4EGB",
  59: "MATE5EGB",
  60: "MATE6EGB",
  61: "MATE7EGB",
  62: "MATE8EGB",
  63: "MATE9EGB",
}

let librosExtension = {
    1: "libro de Biología de 1 BGU",
    2: "libro de Biología de 2 BGU",
    3: "libro de Biología de 3 BGU",
    4: "libro de Educación Cultural y Artística de 1 BGU",
    5: "libro de Educación Cultural y Artística de 2 BGU",
    6: "libro de Educación para la Ciudadanía de 1 BGU",
    7: "libro de Educación para la Ciudadanía de 2 BGU",
    8: "libro de Emprendimiento de 1 BGU",
    9: "libro de Emprendimiento de 2 BGU",
    10: "libro de Emprendimiento de 3 BGU",
    11: "libro de Filosofía de 1 BGU",
    12: "libro de Filosofía de 2 BGU",
    13: "libro de Física de 1 BGU",
    14: "libro de Física de 2 BGU",
    15: "libro de Física de 3 BGU",
    16: "libro de Historia de 1 BGU",
    17: "libro de Historia de 2 BGU",
    18: "libro de Historia de 3 BGU",
    19: "libro de Lengua y Literatura de 1 BGU",
    20: "libro de Lengua y Literatura de 2 BGU",
    21: "libro de Lengua y Literatura de 3 BGU",
    22: "libro de Matemáticas de 1 BGU",
    23: "libro de Matemáticas de 2 BGU",
    24: "libro de Matemáticas de 3 BGU",
    25: "libro de Química de 1 BGU",
    26: "libro de Química de 2 BGU",
    27: "libro de Química de 3 BGU",
    28: "libro de Ciencias Naturales de 10 EGB",
    29: "libro de Ciencias Naturales de 2 EGB",
    30: "libro de Ciencias Naturales de 3 EGB",
    31: "libro de Ciencias Naturales de 4 EGB",
    32: "libro de Ciencias Naturales de 5 EGB",
    33: "libro de Ciencias Naturales de 6 EGB",
    34: "libro de Ciencias Naturales de 7 EGB",
    35: "libro de Ciencias Naturales de 8 EGB",
    36: "libro de Ciencias Naturales de 9 EGB",
    37: "libro de Estudios Sociales de 10 EGB",
    38: "libro de Estudios Sociales de 2 EGB",
    39: "libro de Estudios Sociales de 3 EGB",
    40: "libro de Estudios Sociales de 4 EGB",
    41: "libro de Estudios Sociales de 5 EGB",
    42: "libro de Estudios Sociales de 6 EGB",
    43: "libro de Estudios Sociales de 7 EGB",
    44: "libro de Estudios Sociales de 8 EGB",
    45: "libro de Estudios Sociales de 9 EGB",
    46: "libro de Lengua y Literatura de 10 EGB",
    47: "libro de Lengua y Literatura de 2 EGB",
    48: "libro de Lengua y Literatura de 3 EGB",
    49: "libro de Lengua y Literatura de 4 EGB",
    50: "libro de Lengua y Literatura de 5 EGB",
    51: "libro de Lengua y Literatura de 6 EGB",
    52: "libro de Lengua y Literatura de 7 EGB",
    53: "libro de Lengua y Literatura de 8 EGB",
    54: "libro de Lengua y Literatura de 9 EGB",
    55: "libro de Matemáticas de 10 EGB",
    56: "libro de Matemáticas de 2 EGB",
    57: "libro de Matemáticas de 3 EGB",
    58: "libro de Matemáticas de 4 EGB",
    59: "libro de Matemáticas de 5 EGB",
    60: "libro de Matemáticas de 6 EGB",
    61: "libro de Matemáticas de 7 EGB",
    62: "libro de Matemáticas de 8 EGB",
    63: "libro de Matemáticas de 9 EGB",
}

let librosParaHome = {
  1: "BIOLOGÍA 1BGU",
  2: "BIOLOGÍA 2BGU",
  3: "BIOLOGÍA 3BGU",
  4: "EDUCACIÓN CULTURAL Y ARTÍSTICA 1BGU",
  5: "EDUCACIÓN CULTURAL Y ARTÍSTICA 2BGU",
  6: "EDUCACIÓN PARA LA CIUDADANÍA 1BGU",
  7: "EDUCACIÓN PARA LA CIUDADANÍA 2BGU",
  8: "EMPRENDIMIENTO 1BGU",
  9: "EMPRENDIMIENTO 2BGU",
  10: "EMPRENDIMIENTO 3BGU",
  11: "FILOSOFÍA 1BGU",
  12: "FILOSOFÍA 2BGU",
  13: "FÍSICA 1BGU",
  14: "FÍSICA 2BGU",
  15: "FÍSICA 3BGU",
  16: "HISTORIA 1BGU",
  17: "HISTORIA 2BGU",
  18: "HISTORIA 3BGU",
  19: "LENGUA Y LITERATURA 1BGU",
  20: "LENGUA Y LITERATURA 2BGU",
  21: "LENGUA Y LITERATURA 3BGU",
  22: "MATEMÁTICAS 1BGU",
  23: "MATEMÁTICAS 2BGU",
  24: "MATEMÁTICAS 3BGU",
  25: "QUÍMICA 1BGU",
  26: "QUÍMICA 2BGU",
  27: "QUÍMICA 3BGU",
  28: "CIENCIAS NATURALES 10EGB",
  29: "CIENCIAS NATURALES 2EGB",
  30: "CIENCIAS NATURALES 3EGB",
  31: "CIENCIAS NATURALES 4EGB",
  32: "CIENCIAS NATURALES 5EGB",
  33: "CIENCIAS NATURALES 6EGB",
  34: "CIENCIAS NATURALES 7EGB",
  35: "CIENCIAS NATURALES 8EGB",
  36: "CIENCIAS NATURALES 9EGB",
  37: "ESTUDIOS SOCIALES 10EGB",
  38: "ESTUDIOS SOCIALES 2EGB",
  39: "ESTUDIOS SOCIALES 3EGB",
  40: "ESTUDIOS SOCIALES 4EGB",
  41: "ESTUDIOS SOCIALES 5EGB",
  42: "ESTUDIOS SOCIALES 6EGB",
  43: "ESTUDIOS SOCIALES 7EGB",
  44: "ESTUDIOS SOCIALES 8EGB",
  45: "ESTUDIOS SOCIALES 9EGB",
  46: "LENGUA Y LITERATURA 10EGB",
  47: "LENGUA Y LITERATURA 2EGB",
  48: "LENGUA Y LITERATURA 3EGB",
  49: "LENGUA Y LITERATURA 4EGB",
  50: "LENGUA Y LITERATURA 5EGB",
  51: "LENGUA Y LITERATURA 6EGB",
  52: "LENGUA Y LITERATURA 7EGB",
  53: "LENGUA Y LITERATURA 8EGB",
  54: "LENGUA Y LITERATURA 9EGB",
  55: "MATEMÁTICAS 10EGB",
  56: "MATEMÁTICAS 2EGB",
  57: "MATEMÁTICAS 3EGB",
  58: "MATEMÁTICAS 4EGB",
  59: "MATEMÁTICAS 5EGB",
  60: "MATEMÁTICAS 6EGB",
  61: "MATEMÁTICAS 7EGB",
  62: "MATEMÁTICAS 8EGB",
  63: "MATEMÁTICAS 9EGB",
}


async function getBookConfig(libroID) {
    let unidades = ""
    let unidadesYExtras = ""
    let titulos = ""
    let config = {};
    let libro = libros[libroID];
    let biologia = [1, 2, 3]
    let educacionCulturalArtistica = [4, 5]
    let educacionCiudadania = [6, 7]
    let emprendimiento = [8, 9, 10]
    let filosofia = [11, 12]
    let fisica = [13, 14, 15]
    let historia = [16, 17, 18]
    let lenguaLiteratura = [19, 20, 21, 46, 47, 48, 49, 50, 51, 52, 53, 54]
    let matematicas = [22, 23, 24, 55, 56, 57, 58, 59, 60, 61, 62, 63]
    let quimica = [25, 26, 27]
    let cienciasNaturales = [28, 29, 30, 31, 32, 33, 34, 35, 36]
    let estudiosSociales = [37, 38, 39, 40, 41, 42, 43, 44, 45]
    let libroExtendido = librosExtension[libroID]
    let dataToSend = {
        libro: libro
    };

    // Convertir los datos a parámetros de URL
    const queryString = new URLSearchParams(dataToSend).toString();

    try {
        const response = await fetch(`https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/obtenerDiccionarios?${queryString}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
            }
        });
        const data = await response.json();
        titulos = data.titulos;
        unidades = data.unidades;
        unidadesYExtras = data.unidadesYExtras;


    } catch (error) {
        console.error('Error:', error);
    }

    if (matematicas.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "azul",
      libro: libro,
      backgroundImage: fondoAzul,
      modalColor: "#2053A1",
      generalBackgroundColor: "#BED2ED",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#2053A1",
      checkmarkHoverColor: "#407FD0",
      checkmarkCheckedColor: "#3769B1",
      scrollbarTrackColor: "#2053A1",
      scrollbarThumbColor: "#BED2ED",
      scrollbarThumbHoverColor: "#2053A1",
      botonAceptarColor: "#BED2ED",
      botonAceptarColorHover: "#7D8FA2",
      chatContainerColor: "white",
      userBubbleColor: "#99B8DF",
      assistantBubbleColor: "#BED2ED",
      sendButtonColor: "#2053A1",
      sendButtonHoverColor: "#407FD0",
      headerTextColor: "black",
      fondoDelChat: "#2053A1",
      textAreaBorder: "#ccc",
      btndocxColor: "#2053A1",
      btndocxColorHover: "#407FD0",
      chatButtonColor: "#1C3D6E",
      planificacionesButtonColor: "#2053A1",
      evaluacionesButtonColor: "#3769B1",
      socialButtonColor: "#407FD0",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroLatex",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }
  
  if (quimica.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "verdeQuimica",
      libro: libro,
      backgroundImage: fondoVerde,
      modalColor: "#598838",
      generalBackgroundColor: "#D3E0CB",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#598838",
      checkmarkHoverColor: "#598838",
      checkmarkCheckedColor: "#284E38",
      scrollbarTrackColor: "#598838",
      scrollbarThumbColor: "#D3E0CB",
      scrollbarThumbHoverColor: "#598838",
      botonAceptarColor: "#D3E0CB",
      botonAceptarColorHover: "#B3C6A7",
      chatContainerColor: "white",
      userBubbleColor: "#B7D6A4",
      assistantBubbleColor: "#D3E0CB",
      sendButtonColor: "#598838",
      sendButtonHoverColor: "#598838",
      headerTextColor: "black",
      fondoDelChat: "#598838",
      textAreaBorder: "#ccc",
      btndocxColor: "#598838",
      btndocxColorHover: "#598838",
      chatButtonColor: "#265038",
      planificacionesButtonColor: "#598838",
      evaluacionesButtonColor: "#6D9A4E",
      socialButtonColor: "#81A569",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }
  
  if (fisica.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "verdeFisica",
      libro: libro,
      backgroundImage: fondoVerde,
      modalColor: "#598838",
      generalBackgroundColor: "#D3E0CB",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#598838",
      checkmarkHoverColor: "#598838",
      checkmarkCheckedColor: "#284E38",
      scrollbarTrackColor: "#598838",
      scrollbarThumbColor: "#D3E0CB",
      scrollbarThumbHoverColor: "#598838",
      botonAceptarColor: "#D3E0CB",
      botonAceptarColorHover: "#B3C6A7",
      chatContainerColor: "white",
      userBubbleColor: "#B7D6A4",
      assistantBubbleColor: "#D3E0CB",
      sendButtonColor: "#598838",
      sendButtonHoverColor: "#598838",
      headerTextColor: "black",
      fondoDelChat: "#598838",
      textAreaBorder: "#ccc",
      btndocxColor: "#598838",
      btndocxColorHover: "#598838",
      chatButtonColor: "#265038",
      planificacionesButtonColor: "#598838",
      evaluacionesButtonColor: "#6D9A4E",
      socialButtonColor: "#81A569",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroLatex",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }

  if ((cienciasNaturales.includes(parseInt(libroID))) ||
      (biologia.includes(parseInt(libroID)))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "verde",
      libro: libro,
      backgroundImage: fondoVerde,
      modalColor: "#598838",
      generalBackgroundColor: "#D3E0CB",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#598838",
      checkmarkHoverColor: "#598838",
      checkmarkCheckedColor: "#284E38",
      scrollbarTrackColor: "#598838",
      scrollbarThumbColor: "#D3E0CB",
      scrollbarThumbHoverColor: "#598838",
      botonAceptarColor: "#D3E0CB",
      botonAceptarColorHover: "#B3C6A7",
      chatContainerColor: "white",
      userBubbleColor: "#B7D6A4",
      assistantBubbleColor: "#D3E0CB",
      sendButtonColor: "#598838",
      sendButtonHoverColor: "#598838",
      headerTextColor: "black",
      fondoDelChat: "#598838",
      textAreaBorder: "#ccc",
      btndocxColor: "#598838",
      btndocxColorHover: "#598838",
      chatButtonColor: "#265038",
      planificacionesButtonColor: "#598838",
      evaluacionesButtonColor: "#6D9A4E",
      socialButtonColor: "#81A569",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }
  

  if ((estudiosSociales.includes(parseInt(libroID))) ||
      (historia.includes(parseInt(libroID)))  ||
      (educacionCiudadania.includes(parseInt(libroID)))  ||
      (filosofia.includes(parseInt(libroID)))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "rosa",
      libro: libro,
      backgroundImage: fondoRosa,
      modalColor: "#8F2D5D",
      generalBackgroundColor: "#E5BDCF",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#8F2D5D",
      checkmarkHoverColor: "#8F2D5D",
      checkmarkCheckedColor: "#7C214E",
      scrollbarTrackColor: "#8F2D5D",
      scrollbarThumbColor: "#E5BDCF",
      scrollbarThumbHoverColor: "#8F2D5D",
      botonAceptarColor: "#E5BDCF",
      botonAceptarColorHover: "#D0B2BF",
      chatContainerColor: "white",
      userBubbleColor: "#D59CB6",
      assistantBubbleColor: "#E5BDCF",
      sendButtonColor: "#8F2D5D",
      sendButtonHoverColor: "#8F2D5D",
      headerTextColor: "black",
      fondoDelChat: "#8F2D5D",
      textAreaBorder: "#ccc",
      btndocxColor: "#8F2D5D",
      btndocxColorHover: "#8F2D5D",
      chatButtonColor: "#681541",
      planificacionesButtonColor: "#8F2D5D",
      evaluacionesButtonColor: "#A04670",
      socialButtonColor: "#BA6D90",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }
  
  
  if (lenguaLiteratura.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "morado",
      libro: libro,
      backgroundImage: fondoMorado,
      modalColor: "#53376E",
      generalBackgroundColor: "#D1C1E2",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#53376E",
      checkmarkHoverColor: "#53376E",
      checkmarkCheckedColor: "#432B5A",
      scrollbarTrackColor: "#53376E",
      scrollbarThumbColor: "#D1C1E2",
      scrollbarThumbHoverColor: "#53376E",
      botonAceptarColor: "#D1C1E2",
      botonAceptarColorHover: "#BAAFC5",
      chatContainerColor: "white",
      userBubbleColor: "#C6ABE3",
      assistantBubbleColor: "#D1C1E2",
      sendButtonColor: "#53376E",
      sendButtonHoverColor: "#53376E",
      headerTextColor: "black",
      fondoDelChat: "#53376E",
      textAreaBorder: "#ccc",
      btndocxColor: "#53376E",
      btndocxColorHover: "#53376E",
      chatButtonColor: "#352149",
      planificacionesButtonColor: "#53376E",
      evaluacionesButtonColor: "#6C4495",
      socialButtonColor: "#7A5D97",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }


  if (educacionCulturalArtistica.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "naranja",
      libro: libro,
      backgroundImage: fondoNaranja,
      modalColor: "#DE6620",
      generalBackgroundColor: "#F0C4AD",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#DE6620",
      checkmarkHoverColor: "#DE6620",
      checkmarkCheckedColor: "#B94D0E",
      scrollbarTrackColor: "#DE6620",
      scrollbarThumbColor: "#F0C4AD",
      scrollbarThumbHoverColor: "#DE6620",
      botonAceptarColor: "#F0C4AD",
      botonAceptarColorHover: "#E8CEC0",
      chatContainerColor: "white",
      userBubbleColor: "#F0AE8B",
      assistantBubbleColor: "#F0C4AD",
      sendButtonColor: "#DE6620",
      sendButtonHoverColor: "#DE6620",
      headerTextColor: "black",
      fondoDelChat: "#DE6620",
      textAreaBorder: "#ccc",
      btndocxColor: "#DE6620",
      btndocxColorHover: "#DE6620",
      chatButtonColor: "#B94D0E",
      planificacionesButtonColor: "#DE6620",
      evaluacionesButtonColor: "#E87E46",
      socialButtonColor: "#E99569",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }


  if (emprendimiento.includes(parseInt(libroID))) {
    config = {
    
      libroTitulo: librosParaHome[libroID],
      rama: "rojo",
      libro: libro,
      backgroundImage: fondoRojo,
      modalColor: "#B42B27",
      generalBackgroundColor: "#EBA699",
      checkmarkColor: "#FFFAFB",
      checkmarkBorderColor: "#B42B27",
      checkmarkHoverColor: "#A51D19",
      checkmarkCheckedColor: "#8E2220",
      scrollbarTrackColor: "#B42B27",
      scrollbarThumbColor: "#EBA699",
      scrollbarThumbHoverColor: "#B42B27",
      botonAceptarColor: "#EBA699",
      botonAceptarColorHover: "#E5BDB6",
      chatContainerColor: "white",
      userBubbleColor: "#ED8976",
      assistantBubbleColor: "#EBA699",
      sendButtonColor: "#B42B27",
      sendButtonHoverColor: "#B42B27",
      headerTextColor: "black",
      fondoDelChat: "#B42B27",
      textAreaBorder: "#ccc",
      btndocxColor: "#B42B27",
      btndocxColorHover: "#B42B27",
      chatButtonColor: "#8E2220",
      planificacionesButtonColor: "#B42B27",
      evaluacionesButtonColor: "#CB4F44",
      socialButtonColor: "#E76F59",
      linkParaHablarConElChat: "https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/hablarLibroNormal",
      temasTitulos: titulos,
      unidades: unidades,
      unidadesYExtras: unidadesYExtras
    };
  }

  config["libroExtendido"] = libroExtendido
  return config;
}
export {getBookConfig};