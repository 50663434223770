import React from 'react';
import styles from './VerifyHeader.module.css';
import LogoDidactica from './images/LogoDidactica.png';

const VerifyHeader = () => {
  return (
    <header className={styles.header}>
      <img src={LogoDidactica} alt="Didactica Logo" className={styles.logo} />
    </header>
  );
};

export default VerifyHeader;
