import React, { useState } from 'react';
import styles from './MailParaContraseñaMainContent.module.css';
import AsterImage from './Images/ContrasenaAster.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PasswordResetMainContent = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/forgot-password', { email });
      console.log('Response:', response.data); // Debugging line
      setMessage(response.data.message);
      console.log('Message set:', response.data.message); // Debugging line
      if (response.data.success) {
        setTimeout(() => {
          navigate('/login');
        }, 4000);
      }
    } catch (error) {
      console.log('Error:', error.response ? error.response.data : error.message); // Debugging line
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
        console.log('Error Message set:', error.response.data.message); // Debugging line
      } else {
        setMessage('Error al enviar el correo de recuperación.');
        console.log('Fallback Error Message set'); // Debugging line
      }
    }
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.passwordResetBox}>
        <div className={styles.passwordResetTitle}>Recuperar contraseña</div>
        <div className={styles.asterContainer}>
          <img src={AsterImage} alt="Aster" className={styles.asterImage} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.passwordInputContainer}>
            <input 
              type="text"
              placeholder="Ingresa tu e-mail" 
              value={email} 
              onChange={handleEmailChange} 
              className={styles.passwordInput}
            />
          </div>
          <button type="submit" className={styles.submitButton}>Enviar correo</button>
        {message && <div className={styles.message}>{message}</div>}
        <div href="/login" className={styles.iniciarSesionBoton}>¿Ya tienes cuenta? Inicia sesión aquí</div>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetMainContent;
