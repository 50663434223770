import React, { Component } from 'react';
import styles from './S2.module.css';

class NumericInput extends Component {
    handleChange = (e) => {
        const { value } = e.target;
        const re = /^[0-9\b]+$/; // Solo números
        if (value === '' || re.test(value)) {
            this.props.onChange(e);
        }
    };

    render() {
        const { value, ...props } = this.props;
        return <input type="text" value={value} onChange={this.handleChange} {...props} />;
    }
}

class Seccion2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter(index) {
        this.setState({ hoverIndex: index });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: null });
    }

    render() {
        const { isCheckboxChecked, toggleTemaActivo, unidades, handlePreguntasChange, containerColor, checkmarkColor, checkmarkBorderColor, checkmarkHoverColor, checkmarkCheckedColor, scrollbarTrackColor, scrollbarThumbColor, scrollbarThumbHoverColor } = this.props;

        const containerStyle = {
            backgroundColor: containerColor
        };

        const checkboxStyle = {
            '--checkmark-color': checkmarkColor,
            '--checkmark-border-color': checkmarkBorderColor,
            '--checkmark-hover-color': checkmarkHoverColor,
            '--checkmark-checked-color': checkmarkCheckedColor
        };

        const scrollContainerStyle = {
            '--scrollbar-track-color': scrollbarTrackColor,
            '--scrollbar-thumb-color': scrollbarThumbColor,
            '--scrollbar-thumb-hover-color': scrollbarThumbHoverColor
        };

        return (
            <div className={styles.container} style={containerStyle}>
                <div className={styles.scrollContainer} style={scrollContainerStyle}>
                    {unidades.filter(u => u.activo).map((unidad, indexUnidad) => (
                        <div key={indexUnidad} className={styles.unidad}>
                            <div className={styles.unidadInfo}>
                                <span className={styles.titulo}>{unidad.titulo}</span>
                            </div>
                            {unidad.temas.map((tema) => (
                                <div key={tema.id} className={styles.tema}>
                                    <label className={styles.checkboxContainer} style={checkboxStyle}>
                                        <input
                                            type="checkbox"
                                            checked={tema.activo}
                                            onChange={() => toggleTemaActivo(unidad.id, tema.id)}
                                        />
                                        <span className={styles.checkmark}></span>
                                    </label>
                                    <div className={styles.temaInfo}>
                                        <span className={styles.nombreTema}>{tema.nombre}</span>
                                        <NumericInput
                                            min="0"
                                            className={styles.inputPreguntas}
                                            value={tema.npreguntas}
                                            onChange={(e) => handlePreguntasChange(unidad.id, tema.id, e.target.value)}
                                            placeholder="N. preguntas"
                                            disabled={isCheckboxChecked || !tema.activo}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Seccion2;
