import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './MainContent.module.css';
import fondoVerde from './images/FondoHomeLibro_Negro.png';
import asterSolo from './images/AsterTriste.png';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import logoImage from './images/LogoDidactica.png';
import { AuthContext } from '../AuthContext/AuthContext'; // Asegúrate de ajustar la ruta según tu estructura de carpetas

function NotFound() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isAuthenticated } = useContext(AuthContext); // Usa el contexto de autenticación

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={styles.fondoPagina} style={{ backgroundImage: `url(${fondoVerde})` }}></div>
      
      <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={styles.leftSection}>
          <img src={logoImage} alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.rightSection}>
          <button className={styles.loginButton}>¡Bienvenido, Diego!</button>
        </div>
      </header>

      <div className={styles.cuadro}>
        <div className={styles.row}>
          <div className={styles.columnLeft}>
            <p className={styles.textBold}>ERROR 404: not found</p>
            <p className={styles.textNormal}>No he podido encontrar el libro que estás buscando.</p>
            <Link to={isAuthenticated ? "/home" : "/login"} className={styles.link}>
                <button className={styles.volverButton}>Volver</button>
            </Link>
          </div>
          <div className={styles.columnRight}>
            <img src={asterSolo} alt="Aster Solo" className={styles.image} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
