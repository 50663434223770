import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../AuthContext/AuthContext';
import styles from './HomeMainContent.module.css';
import banner from './images/Profesor.png';
import fondoLibro from './images/fondo_libros.png'; // Importa la imagen de fondo

const HomeMainContent = () => {
  const [books, setBooks] = useState([]);
  const { user, loading, isAuthenticated } = useContext(AuthContext);
  const [hoveredBook, setHoveredBook] = useState(null);

  useEffect(() => {
    if (user) {
      const fetchBooks = async () => {
        try {
          const response = await axios.get(`https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/professors/${user.id}/books`);
          setBooks(response.data);
        } catch (error) {
          console.error('Error fetching books', error);
        }
      };

      fetchBooks();
    }
  }, [user]);

  if (loading) {
    return <div>Cargando..</div>;
  }

  if (!isAuthenticated) {
    window.location.href = '/login';
    return;
  }

  const getImagePath = (title) => {
    if (!title) return ''; // Manejar títulos undefined
    const sanitizedTitle = title.trim(); // Elimina los espacios adicionales al principio y al final

    // Extraer la materia y el nivel del título
    const match = sanitizedTitle.match(/([A-Za-záéíóúÁÉÍÓÚñÑ\s]+)\s?(\d+)(EGB|BGU)/);
    if (!match) return ''; // Si no coincide el formato, retorna vacío

    const materia = match[1].trim();
    const año = match[2];
    const nivel = match[3];

    // Construir la ruta de la imagen basada en la jerarquía de carpetas
    const basePath = `./Portadas/${nivel}/${materia}`;
    const imagePath = `${basePath}/${materia} ${año}${nivel}.png`;

    try {
      return require(`${imagePath}`);
    } catch (err) {
      console.error('Error loading image:', err);
      return ''; // Retorna vacío si no encuentra la imagen
    }
  };

  const getBookTitleAndSubtitle = (title) => {
    const match = title.match(/([A-Za-záéíóúÁÉÍÓÚñÑ\s]+)\s?(\d+)(EGB|BGU)/);
    if (match) {
      const materia = match[1];
      const año = match[2];
      const nivel = match[3] === 'BGU' ? 'BGU' : 'EGB';

      const sufijos = {
        '1': 'ero',
        '2': 'do',
        '3': 'ero',
        '4': 'to',
        '5': 'to',
        '6': 'to',
        '7': 'mo',
        '8': 'vo',
        '9': 'no',
        '10': 'mo',
      };

      const añoTexto = `${año}${sufijos[año] || ''}`; // Agrega el sufijo correspondiente

      return {
        title: `Libro de ${materia}`,
        subtitle: `${añoTexto} ${nivel}`
      };
    }
    return {
      title: title,
      subtitle: ''
    };
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.bannerContainer}>
        <div className={styles.banner}>
          <img src={banner} alt="Banner" className={styles.bannerImage} />
        </div>
      </div>
      <div className={styles.booksContainer}>
        {books.map((book) => {
          const { title, subtitle } = getBookTitleAndSubtitle(book.titulo);
          return (
            <Link to={`/libros/${book.id}`} key={book.id} className={styles.bookLink}>
              <div
                className={`${styles.bookCard} ${hoveredBook === book.id ? styles.active : ''}`}
                onMouseEnter={() => setHoveredBook(book.id)}
                onMouseLeave={() => setHoveredBook(null)}
                style={{
                  backgroundImage: hoveredBook === book.id ? `url(${fondoLibro})` : 'none'
                }}
              >
                <div className={styles.bookCardInner}>
                  <img src={getImagePath(book.titulo)} alt={book.titulo} className={styles.bookImage} />
                  <div className={styles.bookDetails}>
                    <h3>{hoveredBook === book.id ? title : book.titulo}</h3>
                    {hoveredBook === book.id && <div>{subtitle}</div>}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default HomeMainContent;
