import React, { Component } from 'react';
import styles from './S2.module.css';

const NumericInput = ({ onChange, value, ...props }) => {
        const handleChange = (e) => {
          const { value } = e.target;
          const re = /^[0-9\b]+$/; // Solo números
          if (value === '' || re.test(value)) {
            onChange(e);
          }
        };
      
        return <input type="text" value={value} onChange={handleChange} {...props} />;
    };
class Seccion2 extends Component {
    state = {
        hoverIndex: null
    };

    handleMouseEnter = (index) => {
        this.setState({ hoverIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoverIndex: null });
    };

    render () {
        const { toggleTemaActivo, unidades, containerColor, checkmarkColor, checkmarkBorderColor, checkmarkHoverColor, checkmarkCheckedColor, scrollbarTrackColor, scrollbarThumbColor, scrollbarThumbHoverColor } = this.props;
        const { hoverIndex } = this.state
        
        const containerStyle = {
            backgroundColor: containerColor
        };
        const checkBoxStyle = {
            '--checkmark-color': checkmarkColor,
            '--checkmark-border-color': checkmarkBorderColor,
            '--checkmark-hover-color': checkmarkHoverColor,
            '--checkmark-checked-color': checkmarkCheckedColor
        };
        const scrollContainerStyle = {
            '--scrollbar-track-color': scrollbarTrackColor,
            '--scrollbar-thumb-color': scrollbarThumbColor,
            '--scrollbar-thumb-hover-color': scrollbarThumbHoverColor
        };

        return (
            <div className={styles.container} style={containerStyle}> {/* Contenedor agregado para el scroll */}
                <div className={styles.scrollContainer} style={scrollContainerStyle}>
                    {unidades.filter(u => u.activo).map((unidad, indexUnidad) => (
                        <div key={indexUnidad} className={styles.unidad}>
                            <div className={styles.unidadInfo}>
                                <span className={styles.titulo}>{unidad.titulo}</span>
                            </div>        
                                {unidad.temas.map((tema) => (
                                    <div key={tema.id} className={styles.tema}>
                                        <label className={styles.checkboxContainer} style={checkBoxStyle}>
                                        <input
                                            type="checkbox"
                                            checked={tema.activo}
                                            onChange={() => toggleTemaActivo(unidad.id, tema.id)}
                                        />
                                        <span className={styles.checkmark}></span>
                                        </label>
                                        <div className={styles.temaInfo}>
                                        <span className={styles.nombreTema}>{tema.nombre}</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
}

export default Seccion2;
