import React, { Component } from 'react';
import styles from './Overlay.module.css';
import Seccion1 from './S1/S1';
import Seccion2 from './S2/S2';
import { resetChat } from '../Seccion1/S1';

class Overlay extends Component {
    constructor(props) {
        super(props);

        const initialUnidades = Array.isArray(props.unidades) ? props.unidades : [];


        this.state = {
            isCheckboxChecked: true,
            isVisible: true,
            totalPreguntas: '',
            unidades: initialUnidades,
            hoverIndex: null, // Estado para manejar el hover
            isRedirecting: false
        };

        // Enlazar métodos a la instancia de la clase
        this.handleAceptar = this.handleAceptar.bind(this);
        this.toggleUnidadActivo = this.toggleUnidadActivo.bind(this);
        this.toggleTemaActivo = this.toggleTemaActivo.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handlePreguntasChange = this.handlePreguntasChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.extraerInformacion = this.extraerInformacion.bind(this);
        this.temasActivos = this.temasActivos.bind(this);
        this.puedeAceptar = this.puedeAceptar.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    handleMouseEnter() {
        this.setState({ hoverIndex: true });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: false });
    }

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
          return false;
        }
    
        try {
          const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            return true;
          } else {
            localStorage.removeItem('token');
            return false;
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          return false;
        }
    }

    async handleAceptar() {
        const tokenIsValid = await this.verifyToken();
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.")
            return;
        }

        resetChat();
        this.handleClose();
        this.props.setResponseData(this.extraerInformacion());
    }

    extraerInformacion() {
        const { unidades } = this.state;
        for (const unidad of unidades) {
            if (unidad.activo) {
                for (const tema of unidad.temas) {
                    if (tema.activo) {
                        const temaId = tema.id % 100;
                        return parseFloat(`${unidad.id}.${temaId.toString().padStart(2, '0')}`);
                    }
                }
            }
        }
    }

    toggleUnidadActivo(index) {
        const nuevasUnidades = this.state.unidades.map((unidad, i) =>
            ({ ...unidad, activo: i === index ? !unidad.activo : false })
        );
        this.setState({ unidades: nuevasUnidades });
    }

    toggleTemaActivo(idUnidad, idTema) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    ({ ...tema, activo: tema.id === idTema ? !tema.activo : false })
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
        this.setState({ unidades: nuevasUnidades });
    }

    toggleCheckbox() {
        this.setState(prevState => {
            if (!prevState.isCheckboxChecked) {
                const nuevasUnidades = prevState.unidades.map(unidad => ({
                    ...unidad,
                    temas: unidad.temas.map(tema => ({ ...tema, npreguntas: '' }))
                }));
                return { isCheckboxChecked: !prevState.isCheckboxChecked, unidades: nuevasUnidades };
            }
            return { isCheckboxChecked: !prevState.isCheckboxChecked };
        });
    }

    handlePreguntasChange(idUnidad, idTema, value) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    tema.id === idTema ? { ...tema, npreguntas: value } : tema
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
        this.setState({ unidades: nuevasUnidades });
    }

    handleClose() {
        this.setState({ isVisible: false });
    }

    temasActivos() {
        return this.state.unidades.some(unidad => unidad.temas.some(tema => tema.activo));
    }

    puedeAceptar() {
        const { isCheckboxChecked, unidades } = this.state;

        const preguntasTotalesValidas = isCheckboxChecked;

        const preguntasIndividualesValidas = !isCheckboxChecked && unidades.some(unidad =>
            unidad.temas.some(tema =>
                tema.npreguntas !== '' && !isNaN(Number(tema.npreguntas)) && Number(tema.npreguntas) > 0
            )
        );

        const temasActivos = unidades.some(unidad =>
            unidad.temas.some(tema => tema.activo)
        );

        return (preguntasTotalesValidas && temasActivos) || preguntasIndividualesValidas;
    }

    render() {
        const { isCheckboxChecked, totalPreguntas, unidades, isVisible, hoverIndex, isRedirecting } = this.state;

        if (isRedirecting) {
            window.location.href = '/login';
            return;
        }

        if (!isVisible) return null;

        // Aplicar estilo basado en el estado de hover
        const botonAceptarStyle = {
            backgroundColor: hoverIndex ? this.props.botonAceptarColorHover : this.props.botonAceptarColor,
            '--boton-aceptar-color': this.props.botonAceptarColorHover
        };

        return (
            <div className={styles.overlay} style={{ backgroundColor: this.props.modalColor}}>
                <div className={styles.modal} style={{ backgroundColor: this.props.modalColor }}>
                    <form>
                        <Seccion1
                            unidades={unidades}
                            onToggleUnidad={this.toggleUnidadActivo}
                            containerColor={this.props.generalBackgroundColor}
                            optionGroupColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                        />
                        <Seccion2
                            unidades={unidades}
                            toggleTemaActivo={this.toggleTemaActivo}
                            containerColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                        />
                        <button
                            type="button"
                            onClick={this.handleAceptar}
                            disabled={!this.puedeAceptar()}
                            className={`${styles.acceptButton} ${!this.puedeAceptar() ? styles.buttonDisabled : ''}`}
                            style={botonAceptarStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            ACEPTAR
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Overlay;
