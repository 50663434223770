import React, { Component } from 'react';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import styles from './S1.module.css';
import Imagen1 from './images/aster.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import fondochat from './images/fondochat.png';


let listaDeMensajesBack = [{"role":"system", "content":"prompt"}, {"role":"assistant", "content":"Hola! Soy tu libro preferido, en qué puedo ayudarte?"}];
let chatIDFuncional = 9;

const resetChat = () => {
    chatIDFuncional = 9;
    listaDeMensajesBack = [{"role":"system", "content":"prompt"}, {"role":"assistant", "content":"Hola! Soy tu libro preferido, en qué puedo ayudarte?"}];
};

class Seccion1Chat extends Component {
    constructor(props) {
        super(props);

        const { responseData } = this.props;

        this.state = {
            messages: responseData ? [{ role: "system", content: responseData }] : [{ role: "system", content: `Soy tu ${this.props.libroExtendido}` }],
            inputMessage: "",
            isSending: false,
            isTyping: false,
            isChatActive: true,
            imageData: null,
            hoverIndex: null,
            isRedirecting: false
        };

        this.chatMessagesRef = React.createRef();

        this.sendMessage = this.sendMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    componentDidMount() {
        const { responseData, temasTitulos } = this.props;
        if (responseData) {
            this.setState(prevState => ({
                messages: [...prevState.messages, { role: "system", content: `¡Bienvenido al tema de ${temasTitulos[responseData]}!` }]
            }), this.scrollToBottom);
        } else {
            this.scrollToBottom();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.messages.length !== this.state.messages.length) {
            this.scrollToBottom();
        }
    }

    scrollToBottom() {
        if (this.chatMessagesRef.current) {
            this.chatMessagesRef.current.scrollTop = this.chatMessagesRef.current.scrollHeight;
        }
    }

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        
        try {
            const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                return true;
            } else {
                localStorage.removeItem('token');
                return false;
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('token');
            return false;
        }
    }

    async sendMessage() {
        const { inputMessage } = this.state;
        const { responseData, backendUrl, identificador, rama, libro } = this.props;
        const trimmedMessage = inputMessage.trim();
        if (!trimmedMessage) return;

        const tokenIsValid = await this.verifyToken();
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.")
            return;
        }

        const newMessage = { role: "user", content: trimmedMessage };
        listaDeMensajesBack.push({ role: "user", content: trimmedMessage });
        this.setState(prevState => ({
            messages: [...prevState.messages, newMessage],
            inputMessage: '',
            isSending: true,
            isTyping: true,
            isChatActive: false
        }), this.scrollToBottom);

        let dataToSend = {
            messages: listaDeMensajesBack,
            identificador: identificador,
            rama: rama,
            libro: libro,
            unidadTema: responseData
        };

        fetch(backendUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
            },
            body: JSON.stringify(dataToSend)
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                isSending: false,
                isTyping: false,
                isChatActive: true,
                messages: data.response && !prevState.messages.find(m => m.content === data.response) 
                    ? [...prevState.messages, { role: "assistant", content: data.response }]
                    : prevState.messages
            }), this.scrollToBottom);

            if (data.response) {
                listaDeMensajesBack = data.messages;
            }

            if (data.img64) {
                const imageUrl = `data:image/png;base64,${data.img64}`;
                const imageMessage = { role: "assistant", content: "", image: imageUrl };
                this.setState(prevState => ({
                    messages: [...prevState.messages, imageMessage]
                }), this.scrollToBottom);
            }
        })
        .catch(error => {
            this.setState({
                isSending: false,
                isTyping: false,
                isChatActive: true
            });
            console.error('Error:', error);
        });
    }

    handleInputChange(e) {
        this.setState({ inputMessage: e.target.value });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
        }
    }

    handleMouseEnter() {
        this.setState({ hoverIndex: true });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: false });
    }

    renderMessageContent(message) {
        const parts = message.split(/(\$[\s\S]*?\$)/g);
        return parts.map((part, index) => {
            if (part.startsWith('$') && part.endsWith('$')) {
                return <BlockMath key={index}>{part.slice(1, -1)}</BlockMath>;
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    }

    render() {
        const { messages, inputMessage, isSending, isTyping, isChatActive, hoverIndex } = this.state;
        const { containerColor, chatContainerColor, chatHeaderColor, chatFormColor, userBubbleColor, assistantBubbleColor, headerTextColor, sendButtonColor, sendButtonHoverColor } = this.props;

        const botonEnviarStyle = {
            backgroundColor: hoverIndex ? this.props.sendButtonHoverColor : this.props.sendButtonColor,
        };

        if (this.state.isRedirecting) {
            window.location.href = '/login';
            return;
        }

        return (
            <div className={styles.container} style={{ backgroundColor: containerColor }}>
                <div className={styles.rightRectangle}>
                    <div className="custom-section">
                        <div className={styles.chatcontainer} style={{ backgroundColor: chatContainerColor }}>
                            <div className={styles.chatheader} style={{ backgroundColor: chatHeaderColor }}>
                                <div className={styles.headercontent}>
                                    <div className={styles.profilepicture}>
                                        <img src={Imagen1} alt="Profile" />
                                    </div>
                                    <h2 style={{ fontFamily: 'Poppins, sans-serif', color: headerTextColor }}>Aster asistente docente</h2>
                                </div>
                            </div>
                            <div className={styles.chatmessages} ref={this.chatMessagesRef}>
                                {messages.map((message, index) => (
                                    <div key={index} className={`${styles.messagebubble} ${message.role === "user" ? styles.user : styles.assistant}`}
                                         style={message.role === "user" ? { backgroundColor: userBubbleColor } : { backgroundColor: assistantBubbleColor }}>
                                        {message.image ? (
                                            <img src={message.image} alt="Fetched from backend" className={styles.scaledImage} />
                                        ) : (
                                            this.renderMessageContent(message.content)
                                        )}
                                    </div>
                                ))}
                                {isTyping && <div className={`${styles.messagebubble} ${styles.typing}`}>Escribiendo...</div>}
                            </div>
                            <form className={styles.chatform} onSubmit={(e) => {
                                e.preventDefault();
                                this.sendMessage();
                            }} style={{ backgroundColor: chatFormColor }}>
                                <textarea className={styles.formcontrol}
                                    value={inputMessage}
                                    onChange={this.handleInputChange}
                                    placeholder="Escríbeme tu mensaje"
                                    required
                                    rows="1"
                                    onKeyDown={this.handleKeyDown}
                                    disabled={!isChatActive}
                                ></textarea>
                                <button type="submit" className={styles.btnsend} style={botonEnviarStyle} disabled={!isChatActive || isSending} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'white' }} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seccion1Chat;
export { resetChat };
