import React, { useState } from 'react';
import axios from 'axios';

const verifyToken = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.ok) {
      return true;
    } else {
      localStorage.removeItem('token');
      return false;
    }
  } catch (error) {
    console.error('Error verifying token:', error);
    localStorage.removeItem('token');
    return false;
  }
};

const ReportBugOverlay = ({ onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);

    const tokenIsValid = await verifyToken();
    if (!tokenIsValid) {
      alert("Token caducado. Vuelve a iniciar sesión.");
      window.location.href = '/login';
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }

    try {
      await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/report', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Reporte enviado!');
    } catch (error) {
      console.error('Error enviando el reporte:', error);
      alert('Error enviando el reporte');
    }

    onClose();
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.overlayContent}>
        <form onSubmit={handleSubmit}>
         <div style={styles.uploadContainer}>
            <label htmlFor="imageUpload" style={styles.uploadLabel}>
              Reportar un problema
            </label>
          </div>
          <div style={styles.uploadContainer}>
            <textarea 
              placeholder="Título del problema." 
              value={title} 
              onChange={handleTitleChange}
              style={styles.textareaTitle}
              required
            />
             <div style={styles.customFileUpload}>
              <input 
                type="file" 
                id="imageUpload" 
                onChange={handleImageChange} 
                style={styles.uploadInput} 
                accept="image/jpeg, image/png, image/jpg"
              />
              <label htmlFor="imageUpload" style={styles.customButton}>Elegir archivo</label>
              <span style={styles.fileName}>{image ? image.name : 'No se eligió ningún archivo.'}</span>
            </div>
          </div>
          <div>
            <textarea 
              placeholder="Descripción del error." 
              value={description} 
              onChange={handleDescriptionChange}
              style={styles.textareaDescription}
              required
            />
          </div>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.submitButton} disabled={isButtonDisabled}>Enviar</button>
            <button type="button" style={styles.closeButton} onClick={onClose}>Cerrar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  overlayContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '50vw',
    height: 'auto',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  uploadContainer: {
    marginBottom: '20px',
  },
  customFileUpload: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadInput: {
    display: 'none',  // Oculta el input real
  },
  uploadLabel: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Poppins, sans-serif',
  },
  customButton: {
    backgroundColor: '#13136B',
    color: 'white',
    padding: '5px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: 'Poppins, sans-serif',
    marginRight: '10px',
  },
  fileName: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
  },
  textareaTitle: {
    width: 'calc(100% - 40px)',
    height: '50px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    fontSize: '14px',
    marginBottom: '10px',
  },
  textareaDescription: {
    width: 'calc(100% - 40px)',
    height: '150px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    resize: 'none',
    fontSize: '14px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  submitButton: {
    backgroundColor: '#13136B',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  closeButton: {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default ReportBugOverlay;
