import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './RegisterMainContent.module.css';
import gradiente from './images/GradienteRegister.mp4';
import '@fortawesome/fontawesome-free/css/all.min.css';

const RegisterMainContent = () => {
  const [formData, setFormData] = useState({
    usuario: '',
    contrasena: '',
    nombres: '',
    apellidos: '',
    edad: '',
    email: '',
    institucion: '',
    provincia: '',
    ciudad: '',
    canton: '',
    parroquia: '',
    nivel: ''
  });

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [instituciones, setInstituciones] = useState([]);
  const [filteredInstituciones, setFilteredInstituciones] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [provincias, setProvincias] = useState([]);
  const [filteredProvincias, setFilteredProvincias] = useState([]);
  const [showProvinciaAutocomplete, setShowProvinciaAutocomplete] = useState(false);
  const [cantones, setCantones] = useState([]);
  const [filteredCantones, setFilteredCantones] = useState([]);
  const [showCantonAutocomplete, setShowCantonAutocomplete] = useState(false);
  const [parroquias, setParroquias] = useState([]);
  const [filteredParroquias, setFilteredParroquias] = useState([]);
  const [showParroquiaAutocomplete, setShowParroquiaAutocomplete] = useState(false);
  const [ciudades, setCiudades] = useState([]);
  const [filteredCiudades, setFilteredCiudades] = useState([]);
  const [showCiudadAutocomplete, setShowCiudadAutocomplete] = useState(false);
  const [showNivelDropdown, setShowNivelDropdown] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const institucionRef = useRef(null);
  const provinciaRef = useRef(null);
  const cantonRef = useRef(null);
  const parroquiaRef = useRef(null);
  const ciudadRef = useRef(null);
  const nivelRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstituciones = async () => {
      try {
        const response = await axios.get('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/instituciones');
        if (response.data && Array.isArray(response.data['Sheet 1'])) {
          setInstituciones(response.data['Sheet 1']);
          const provincias = Array.from(new Set(response.data['Sheet 1'].map(inst => inst.Provincia)));
          setProvincias(provincias);
          const cantones = Array.from(new Set(response.data['Sheet 1'].map(inst => inst.Cantón)));
          setCantones(cantones);
          const parroquias = Array.from(new Set(response.data['Sheet 1'].map(inst => inst.Parroquia)));
          setParroquias(parroquias);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching instituciones', error);
      }
    };

    fetchInstituciones();
  }, []);

  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        const response = await axios.get('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/ciudades');
        if (response.data && Array.isArray(response.data)) {
          setCiudades(response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        console.error('Error fetching ciudades', error);
      }
    };

    fetchCiudades();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (institucionRef.current && !institucionRef.current.contains(event.target)) {
        setShowAutocomplete(false);
      }
      if (provinciaRef.current && !provinciaRef.current.contains(event.target)) {
        setShowProvinciaAutocomplete(false);
      }
      if (cantonRef.current && !cantonRef.current.contains(event.target)) {
        setShowCantonAutocomplete(false);
      }
      if (parroquiaRef.current && !parroquiaRef.current.contains(event.target)) {
        setShowParroquiaAutocomplete(false);
      }
      if (ciudadRef.current && !ciudadRef.current.contains(event.target)) {
        setShowCiudadAutocomplete(false);
      }      
      if (nivelRef.current && !nivelRef.current.contains(event.target)) {
        setShowNivelDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'edad') {
      if (!/^\d*$/.test(value) || value.length > 2) {
        return;
      }
    }

    setFormData({ ...formData, [name]: value });

    if (name === 'institucion') {
      const filtered = instituciones.filter((inst) =>
        inst.Nombre_Institución.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredInstituciones(filtered);
      setShowAutocomplete(true);
    }

    if (name === 'provincia') {
      const filtered = provincias.filter((prov) =>
        prov.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProvincias(filtered);
      setShowProvinciaAutocomplete(true);
    }

    if (name === 'canton') {
      const filtered = cantones.filter((canton) =>
        canton.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCantones(filtered);
      setShowCantonAutocomplete(true);
    }

    if (name === 'parroquia') {
      const filtered = parroquias.filter((parroquia) =>
        parroquia.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredParroquias(filtered);
      setShowParroquiaAutocomplete(true);
    }

    if (name === 'ciudad') {
      const filtered = ciudades.filter((ciudad) =>
        ciudad.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCiudades(filtered);
      setShowCiudadAutocomplete(true);
    }
  };

  const handleInstitucionSelect = (institucion) => {
    setFormData({
      ...formData,
      institucion: institucion.Nombre_Institución,
      provincia: institucion.Provincia,
      canton: institucion.Cantón,
      parroquia: institucion.Parroquia
    });
    setShowAutocomplete(false);
    setCantones(instituciones.filter(inst => inst.Provincia === institucion.Provincia).map(inst => inst.Cantón));
    setParroquias(instituciones.filter(inst => inst.Cantón === institucion.Cantón).map(inst => inst.Parroquia));
  };

  const handleProvinciaSelect = (provincia) => {
    setFormData({
      ...formData,
      provincia: provincia,
      canton: '',
      parroquia: ''
    });
    setShowProvinciaAutocomplete(false);
    setCantones(instituciones.filter(inst => inst.Provincia === provincia).map(inst => inst.Cantón));
    setParroquias([]);
  };

  const handleCantonSelect = (canton) => {
    setFormData({
      ...formData,
      canton: canton,
      parroquia: ''
    });
    setShowCantonAutocomplete(false);
    setParroquias(instituciones.filter(inst => inst.Cantón === canton).map(inst => inst.Parroquia));
  };
  
  const handleParroquiaSelect = (parroquia) => {
    setFormData({
      ...formData,
      parroquia: parroquia
    });
    setShowParroquiaAutocomplete(false);
  };

  const handleCiudadSelect = (ciudad) => {
    setFormData({
      ...formData,
      ciudad: ciudad
    });
    setShowCiudadAutocomplete(false);
  };

  const handleNivelSelect = (nivel) => {
    setFormData({
      ...formData,
      nivel: nivel
    });
    setShowNivelDropdown(false);
  };

  const handleCheckboxChange = (e) => {
    setAcceptedTerms(e.target.checked);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setError('Debes aceptar los términos y condiciones.');
      return;
    }
    setError(null);
    setSuccess(null);

    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 5000);

    try {
      const response = await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/register', {
        nombre: formData.nombres,
        apellido: formData.apellidos,
        usuario: formData.usuario,
        contrasena: formData.contrasena,
        edad: formData.edad,
        email_personal: formData.email,
        nombre_institucion: formData.institucion,
        ciudad: formData.ciudad,
        canton: formData.canton,
        provincia: formData.provincia,
        parroquia: formData.parroquia,
        nivel_educacion: formData.nivel
      });
      setSuccess(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 4000);
    } catch (error) {
      setError(error.response.data.message || 'El usuario o contraseña no cumple con los requerimientos.');
    }
  };

  const isFormComplete = () => {
    return Object.values(formData).every(value => value !== '') && acceptedTerms;
  };

  return (
    <main className={styles.mainContent}>
      <video className={styles.backgroundVideo} autoPlay loop muted>
        <source src={gradiente} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.overlay}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.crearCuenta}>Crear cuenta</div>
          <div className={styles.row}>
            <div className={`${styles.inputContainer} ${styles.inputContainerUsuario}`}>
              <input
                type="text"
                name="usuario"
                placeholder="Usuario"
                value={formData.usuario}
                onChange={handleChange}
                className={styles.inputusucontr}
                autoComplete="off"
                required
              />
              <span className={`${styles.infoIcon} ${styles.infoIconUsuario}`}>
                <i className="fas fa-info-circle"></i>
              </span>
              <div className={`${styles.tooltip} ${styles.tooltipUsuario}`}>
                El usuario debe tener 6-10 caracteres sin espacios y solo puede contener letras, números, guiones bajos y puntos.
              </div>
            </div>
            <div className={styles.inputContainer}>
              <input
                type={showPassword ? "text" : "password"}
                name="contrasena"
                placeholder="Contraseña"
                value={formData.contrasena}
                onChange={handleChange}
                className={styles.inputusucontr}
                autoComplete="off"
              />
              <span className={styles.eyeIcon} onClick={toggleShowPassword}>
                <i className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}></i>
              </span>
              <span className={styles.infoIcon}>
                <i className="fas fa-info-circle"></i>
              </span>
              <div className={styles.tooltip}>
                La contraseña debe tener al menos 6 caracteres y solo puede contener letras, números y los caracteres _, *, !, #.
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <input
              type="text"
              name="nombres"
              placeholder="Nombres"
              value={formData.nombres}
              onChange={handleChange}
              className={styles.input}
              autoComplete="off"
            />
          </div>
          <div className={styles.row}>
            <input
              type="text"
              name="apellidos"
              placeholder="Apellidos"
              value={formData.apellidos}
              onChange={handleChange}
              className={styles.input}
              autoComplete="off"
            />
            <input
              type="text"
              name="edad"
              placeholder="Edad"
              value={formData.edad}
              onChange={handleChange}
              inputMode="numeric"
              className={`${styles.input} ${styles.inputEdad}`}
              autoComplete="off"
            />
          </div>
          <div className={styles.row}>
            <input
              type="email"
              name="email"
              placeholder="Correo electrónico"
              value={formData.email}
              onChange={handleChange}
              className={styles.input}
              autoComplete="off"
            />
          </div>
          <div className={styles.row} ref={institucionRef}>
              <input
                type="text"
                name="institucion"
                placeholder="Institución Educativa"
                value={formData.institucion}
                onChange={handleChange}
                onFocus={() => setShowAutocomplete(true)}
                className={`${styles.input} ${styles.inputInstitucion}`}
                autoComplete="off"
              />
              {showAutocomplete && filteredInstituciones.length > 0 && (
                <ul className={styles.autocompleteListInsti}>
                  {filteredInstituciones.map((institucion, index) => (
                    <li
                      key={index}
                      onClick={() => handleInstitucionSelect(institucion)}
                      className={styles.autocompleteItem}
                    >
                      {institucion.Nombre_Institución}
                    </li>
                  ))}
                </ul>
              )}
          </div>
          <div className={styles.row} ref={nivelRef}>
                <input
                  type="text"
                  name="nivel"
                  placeholder="Nivel de Educación"
                  value={formData.nivel}
                  onFocus={() => setShowNivelDropdown(true)}
                  readOnly
                  className={styles.input}
                />
                {showNivelDropdown && (
                  <ul className={styles.autocompleteListInsti}>
                    <li
                      onClick={() => handleNivelSelect('Educación General Básica')}
                      className={styles.autocompleteItem}
                    >
                      Educación General Básica
                    </li>
                    <li
                      onClick={() => handleNivelSelect('Bachillerato General Unificado')}
                      className={styles.autocompleteItem}
                    >
                      Bachillerato General Unificado
                    </li>
                  </ul>
                )}
            </div>
          <div className={styles.row} ref={provinciaRef}>
            <div className={styles.column}>
              <input
                type="text"
                name="provincia"
                placeholder="Provincia"
                value={formData.provincia}
                onChange={handleChange}
                onFocus={() => setShowProvinciaAutocomplete(true)}
                className={styles.input}
                autoComplete="off"
              />
              {showProvinciaAutocomplete && filteredProvincias.length > 0 && (
                <ul className={styles.autocompleteListProv}>
                  {filteredProvincias.map((provincia, index) => (
                    <li
                      key={index}
                      onClick={() => handleProvinciaSelect(provincia)}
                      className={styles.autocompleteItem}
                    >
                      {provincia}
                    </li>
                  ))}
                </ul>
                )}
              </div>
              <div className={styles.column} ref={ciudadRef}>
                <input
                  type="text"
                  name="ciudad"
                  placeholder="Ciudad"
                  value={formData.ciudad}
                  onChange={handleChange}
                  onFocus={() => setShowCiudadAutocomplete(true)}
                  className={styles.input}
                  autoComplete="off"
                />
                {showCiudadAutocomplete && filteredCiudades.length > 0 && (
                  <ul className={styles.autocompleteListCiud}>
                    {filteredCiudades.map((ciudad, index) => (
                      <li
                        key={index}
                        onClick={() => handleCiudadSelect(ciudad)}
                        className={styles.autocompleteItem}
                      >
                        {ciudad}
                      </li>
                    ))}
                  </ul>
                )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column} ref={cantonRef}>
              <input
                type="text"
                name="canton"
                placeholder="Cantón"
                value={formData.canton}
                onChange={handleChange}
                onFocus={() => setShowCantonAutocomplete(true)}
                className={styles.input}
                autoComplete="off"
              />
              {showCantonAutocomplete && filteredCantones.length > 0 && (
                <ul className={styles.autocompleteListCant}>
                  {filteredCantones.map((canton, index) => (
                    <li
                      key={index}
                      onClick={() => handleCantonSelect(canton)}
                      className={styles.autocompleteItem}
                    >
                      {canton}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className={styles.column} ref={parroquiaRef}>
              <input
                type="text"
                name="parroquia"
                placeholder="Parroquia"
                value={formData.parroquia}
                onChange={handleChange}
                onFocus={() => setShowParroquiaAutocomplete(true)}
                className={styles.input}
                autoComplete="off"
              />
              {showParroquiaAutocomplete && filteredParroquias.length > 0 && (
                <ul className={styles.autocompleteListParroq}>
                  {filteredParroquias.map((parroquia, index) => (
                    <li
                      key={index}
                      onClick={() => handleParroquiaSelect(parroquia)}
                      className={styles.autocompleteItem}
                    >
                      {parroquia}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className={styles.termsContainer}>
            <input
              type="checkbox"
              id="terms"
              checked={acceptedTerms}
              onChange={handleCheckboxChange}
              className={styles.checkbox}
            />
            <label htmlFor="terms" className={styles.termsLabel}>
              Acepto <a href="/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noopener noreferrer">términos y condiciones</a> para el uso y tratado de mis datos.
            </label>
          </div>
          <button type="submit" className={styles.registerButton} disabled={!isFormComplete() || isButtonDisabled}>Registrar</button>
          {error && <div className={styles.errorMessage}>{error}</div>}
          {success && <div className={styles.successMessage}>{success}</div>}
          <div className={styles.loginLink}>
            <a href="/login">¿Ya tienes cuenta? Inicia sesión aquí</a>
          </div>
        </form>
      </div>
    </main>
  );
};

export default RegisterMainContent;
