import React, { Component } from 'react';
import Seccion1Chat from './Seccion1/S1';
import Overlay from './Overlay/Overlay';
import Volver from './Volver/Volver';
import ReportBugButton from "../Report/ReportBugButton";

class Planificaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOverlayVisible: true,
            responseData: null
        };

        this.handleAccept = this.handleAccept.bind(this);
        this.setResponseData = this.setResponseData.bind(this);
    }

    handleAccept() {
        this.setState({ isOverlayVisible: false });
    }

    setResponseData(responseData) {
        this.setState({ responseData });
    }

    render() {
        const { isOverlayVisible, responseData } = this.state;
        const { 
            unidades, 
            modalColor, 
            generalBackgroundColor, 
            checkmarkColor, 
            checkmarkBorderColor, 
            checkmarkHoverColor, 
            checkmarkCheckedColor, 
            scrollbarTrackColor, 
            scrollbarThumbColor, 
            scrollbarThumbHoverColor, 
            botonAceptarColor, 
            botonAceptarColorHover, 
            chatContainerColor, 
            userBubbleColor, 
            assistantBubbleColor, 
            sendButtonColor, 
            sendButtonHoverColor, 
            headerTextColor, 
            fondoDelChat, 
            textAreaBorder, 
            btndocxColor, 
            btndocxColorHover, 
            linkParaPlanificaciones, 
            temasTitulos 
        } = this.props;

        return (
            <div>  
                <Volver isVisible={true} titulo={this.props.libroExtendido}/>
                <Overlay 
                    unidades={unidades}
                    isVisible={isOverlayVisible} 
                    setResponseData={this.setResponseData} 
                    onAccept={this.handleAccept}
                    modalColor={modalColor}
                    generalBackgroundColor={generalBackgroundColor}
                    checkmarkColor={checkmarkColor}
                    checkmarkBorderColor={checkmarkBorderColor}
                    checkmarkHoverColor={checkmarkHoverColor}
                    checkmarkCheckedColor={checkmarkCheckedColor}
                    scrollbarTrackColor={scrollbarTrackColor}
                    scrollbarThumbColor={scrollbarThumbColor}
                    scrollbarThumbHoverColor={scrollbarThumbHoverColor}
                    botonAceptarColor={botonAceptarColor}
                    botonAceptarColorHover={botonAceptarColorHover}
                    optionGroupColor = {generalBackgroundColor}
                />
                <Seccion1Chat
                    identificador = {this.props.identificador}
                    libro =  {this.props.libro}
                    rama = {this.props.rama}
                    responseData={responseData}
                    temasTitulos={temasTitulos}
                    backendUrl={linkParaPlanificaciones}
                    chatContainerColor={chatContainerColor}
                    chatHeaderColor={generalBackgroundColor}
                    chatFormColor={generalBackgroundColor}
                    userBubbleColor={userBubbleColor}
                    assistantBubbleColor={assistantBubbleColor}
                    headerTextColor={headerTextColor}
                    sendButtonColor={sendButtonColor}
                    sendButtonHoverColor={sendButtonHoverColor}
                    fondoDelChat={fondoDelChat}
                    textAreaBorder={textAreaBorder}
                    btndocxColor={btndocxColor}
                    btndocxColorHover={btndocxColorHover}
                />
                <ReportBugButton/>
            </div>
        );
    }
}

export default Planificaciones;
