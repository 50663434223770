import React from 'react';
import styles from './RegisterPage.module.css';
import RegisterHeader from './Header/RegisterHeader';
import RegisterMainContent from './MainContent/RegisterMainContent';
import Footer from '../LandingPage/Footer/Footer';

const RegisterPage = () => {
  return (
    <div className={styles.registerPage}>
      <RegisterHeader />
      <RegisterMainContent />
      <Footer />
    </div>
  );
};

export default RegisterPage;
