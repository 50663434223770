import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import setupAxiosInterceptors from './setupAxiosInterceptors';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
      setupAxiosInterceptors(logout);

        const fetchUser = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                try {
                    console.log('Fetching user...');
                    const response = await axios.get('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me');
                    setUser(response.data);
                    setIsAuthenticated(true);
                } catch (error) {
                    console.error('Error fetching user', error);
                    localStorage.removeItem('token'); // Remover token inválido
                    setIsAuthenticated(false);
                    setUser(null);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const login = async (usuario, contrasena) => {
        try {
            const response = await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/login', { usuario, contrasena });
            if (response.data && response.data.token) {
                localStorage.setItem('token', response.data.token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                setUser(response.data.user);
                setIsAuthenticated(true);
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            console.error('Error during login', error.response.data);
            throw error.response.data.message || 'Usuario o contraseña incorrectos';
        }
    };

    const logout = async () => {
        try {
            await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/logout', {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        } catch (error) {
            console.error('Error during logout', error);
        } finally {
            localStorage.removeItem('token');
            setIsAuthenticated(false);
            setUser(null);
            window.location.href = '/login';
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, loading, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
