import React from 'react';
import Header from '../Verify/Header/VerifyHeader'
import PasswordResetMainContent from './MainContent/PasswordResetMainContent';
import Footer from '../LandingPage/Footer/Footer'

const PasswordReset = () => {
  return (
    <div>
        <Header/>
        <PasswordResetMainContent />
        <Footer/>
    </div>
  );
};

export default PasswordReset;
