import React from 'react';
import Header from './Header/Header';
import MainContent from './MainContent/MainContent';
import Footer from './FooterLanding/Footer';
import styles from './LandingPage.modules.css';

function LandingPage() {
  return (
    <div className={styles.LandingPage}>
      <Header />
      <MainContent />
      <Footer />
    </div>
  );
}

export default LandingPage;
