import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MainContent.module.css'; // Nota el cambio en el nombre del archivo
import image from './images/Aster-.-asistente-docente.png'; // asegúrate de tener la imagen en la carpeta adecuada

function MainContent() {  
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <main className={styles['main-content']}>
      <div className={`${styles.column} ${styles['text-column']}`}>
        <h1>¡Inicia sesión y descubre la educación del futuro!</h1>
        <p>Transforma tu aprendizaje con tecnología de vanguardia.</p>
        <button onClick={handleLoginClick}>Ingresar</button>
        
      </div>
      <div className={`${styles.column} ${styles['image-column']}`}>
        <img src={image} alt="Educación del futuro" />
      </div>
    </main>
  );
}

export default MainContent;
