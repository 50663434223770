import React, { useState, useContext } from 'react';
import styles from './HomeHeader.module.css';
import logo from './images/LogoDidactica.png';
import { AuthContext } from '../../AuthContext/AuthContext';
import CodeOverlay from '../MainContent/CodeOverlay';

const HomeHeader = () => {
  const { logout } = useContext(AuthContext);
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const handleLogout = () => {
    logout();
  };

  const handleEnterCode = () => {
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoColumn}>
        <img src={logo} alt="didáctica editorial" className={styles.logo} />
      </div>
      <div className={styles.welcomeColumn}>
        <button className={styles.codeButton} onClick={handleEnterCode}>Ingresar código</button>
        <button className={styles.logoutButton} onClick={handleLogout}>Cerrar sesión</button>
      </div>
      {isOverlayVisible && <CodeOverlay onClose={closeOverlay} />}
    </header>
  );
};

export default HomeHeader;
