import React from 'react';
import { useParams } from 'react-router-dom';
import Menu from './Menu';

const Mediador = ({ identificador }) => {
  const { id } = useParams();

  return (
    <Menu 
      id={id} 
      identificador={identificador} 
      welcomeText="¡Bienvenido, Diego"
    />
  );
};

export default Mediador;
