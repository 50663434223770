import React, { Component } from 'react';
import styles from './S1.module.css'; // Asegúrate de que el archivo CSS está correctamente enlazado

class Seccion1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hoverIndex: null
        };

        this.handleInputValue = this.handleInputValue.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleInputValue(value) {
        // Solo permite números en el valor del input
        return value.replace(/[^0-9]/g, '');
    }

    handleMouseEnter(index) {
        this.setState({ hoverIndex: index });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: null });
    }

    render() {
        const { isCheckboxChecked, toggleCheckbox, totalPreguntas, setTotalPreguntas, unidades, onToggleUnidad, optionGroupColor, checkmarkColor, checkmarkBorderColor, checkmarkHoverColor, checkmarkCheckedColor, scrollbarTrackColor, scrollbarThumbColor, scrollbarThumbHoverColor } = this.props;

        const checkboxStyle = {
            '--checkmark-color': checkmarkColor,
            '--checkmark-border-color': checkmarkBorderColor,
            '--checkmark-hover-color': checkmarkHoverColor,
            '--checkmark-checked-color': checkmarkCheckedColor
        };

        const optionGroupStyle = {
            backgroundColor: optionGroupColor,
            '--scrollbar-track-color': scrollbarTrackColor,
            '--scrollbar-thumb-color': scrollbarThumbColor,
            '--scrollbar-thumb-hover-color': scrollbarThumbHoverColor
        };

        return (
            <div className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <div className={styles.optionGroup} style={optionGroupStyle}>
                            {unidades.map((unidad, index) => (
                                <label key={index} className={styles.checkboxContainer} style={checkboxStyle}>
                                    <p>{unidad.titulo}</p>
                                    <input
                                        type="checkbox"
                                        checked={unidad.activo} 
                                        onChange={() => onToggleUnidad(index)}
                                    />
                                    <span className={styles.checkmark}></span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.optionGroup} style={optionGroupStyle}>
                            <label className={styles.checkboxContainer} style={checkboxStyle}>
                                <p>Dividir preguntas de manera igualitaria en temas seleccionados</p>
                                <input 
                                    type="checkbox" 
                                    checked={isCheckboxChecked} 
                                    onChange={toggleCheckbox}
                                    />
                                <span className={styles.checkmark}></span>
                            </label>
                            <div className={styles.inputGroup}>
                                <label 
                                    htmlFor="numQuestions" 
                                    className={isCheckboxChecked ? '' : styles.labelDisabled}
                                    >
                                    N. preguntas
                                </label>
                                <input  
                                    autoComplete="off"
                                    min="0"
                                    id="numQuestions" 
                                    name="numQuestions" 
                                    value={this.handleInputValue(totalPreguntas)}
                                    onChange={e => setTotalPreguntas(e.target.value)}
                                    disabled={!isCheckboxChecked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seccion1;
