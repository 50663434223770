import React, { Component } from 'react';
import styles from './S1.module.css'; // Asegúrate de que el archivo CSS está correctamente enlazado

class Seccion1 extends Component {
    state = {
        hoverIndex: null
    };

    handleMouseEnter = (index) => {
        this.setState({ hoverIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoverIndex: null });
    };

    render() {
        const { unidades, onToggleUnidad, optionGroupColor, checkmarkColor, checkmarkBorderColor, checkmarkHoverColor, checkmarkCheckedColor, scrollbarTrackColor, scrollbarThumbColor, scrollbarThumbHoverColor } = this.props;

        const checkboxStyle = {
            '--checkmark-color': checkmarkColor,
            '--checkmark-border-color': checkmarkBorderColor,
            '--checkmark-hover-color': checkmarkHoverColor,
            '--checkmark-checked-color': checkmarkCheckedColor
        }

        const optionGroupStyle = {
            backgroundColor: optionGroupColor,
            '--scrollbar-track-color': scrollbarTrackColor,
            '--scrollbar-thumb-color': scrollbarThumbColor,
            '--scrollbar-thumb-hover-color': scrollbarThumbHoverColor
        };

        // Dividimos las unidades en dos columnas
        const firstColumnUnits = [unidades[0], unidades[1], unidades[2]];
        const secondColumnUnits = [unidades[3], unidades[4], unidades[5]];

        return (
            <div className={styles.container}>
                <div className={styles.optionGroup} style={optionGroupStyle}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            {firstColumnUnits.map((unidad, index) => (
                                <label 
                                    key={index} 
                                    className={styles.checkboxContainer}
                                    style={checkboxStyle}
                                >
                                    <p>{unidad.titulo}</p>
                                    <input
                                        type="checkbox"
                                        checked={unidad.activo} 
                                        onChange={() => onToggleUnidad(index)}
                                    />
                                    <span 
                                        className={styles.checkmark} 
                                    ></span>
                                </label>
                            ))}
                        </div>
                        <div className={styles.column}>
                            {secondColumnUnits.map((unidad, index) => (
                                <label 
                                    key={index + 3} 
                                    className={styles.checkboxContainer}
                                    style={checkboxStyle}
                                >
                                    <p>{unidad.titulo}</p>
                                    <input
                                        type="checkbox"
                                        checked={unidad.activo} 
                                        onChange={() => onToggleUnidad(index + 3)}
                                    />
                                    <span 
                                        className={styles.checkmark} 
                                    ></span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seccion1;