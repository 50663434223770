import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from 'react-icons/fa';
import { FaArrowRightFromBracket } from 'react-icons/fa6';
import styles from './MainContent.module.css';
import { AuthContext } from '../../AuthContext/AuthContext';

const WholeContent = (props) => {
    const { logout } = useContext(AuthContext);
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const {
        backgroundImage,
        logoImage,
        asterImage,
        welcomeText,
        chatButtonColor,
        planificacionesButtonColor,
        evaluacionesButtonColor,
        socialButtonColor,
        libroTitulo,
    } = props;

    return (
        <div>
            <div className={styles.fondoPagina} style={{ backgroundImage: `url(${backgroundImage})` }}></div>

            <header className={`${styles.header}`}>
                <div className={styles.leftSection}>
                    <img src={logoImage} alt="Logo" className={styles.logo} />
                </div>
            </header>
            <header className={`${styles.headerEncima}`}>
                <div className={styles.rightSection}>
                    <button className={styles.loginButton}>{welcomeText}</button>
                    <button className={styles.loginButton} onClick={logout} style={{ paddingBottom: '7px' }}>
                        <FaArrowRightFromBracket />
                    </button>

                </div>
            </header>

            <div className={styles.cuadro}>
                <div className={styles.row}>
                    <div className={styles.columnLeft}>
                        <p className={styles.textBold}>{libroTitulo}</p>
                        <Link to="/home" className={styles.link}>
                            <button className={styles.volverButton}>Volver</button>
                        </Link>
                    </div>
                    <div className={styles.columnRight}>
                        <img src={asterImage} alt="Aster Solo" className={styles.image} />
                    </div>
                </div>
            </div>

            <div className={styles.menuContainer}>
                <div className={styles.menu}>
                    <Link to="chat" className={styles.link}>
                        <button
                            className={styles.menuButton}
                            style={{
                                backgroundColor: chatButtonColor,
                                borderTopLeftRadius: '20px'
                            }}>
                            Chat
                        </button>
                    </Link>
                    <Link to="planificaciones" className={styles.link}>
                        <button className={styles.menuButton} style={{ backgroundColor: planificacionesButtonColor }}>Planificaciones</button>
                    </Link>
                    <Link to="evaluaciones" className={styles.link}>
                        <button className={styles.menuButton} style={{ backgroundColor: evaluacionesButtonColor }}>Evaluaciones</button>
                    </Link>
                    <button className={styles.menuButton} style={{ backgroundColor: socialButtonColor }}>
                        <div className={styles.socialMedia}>
                            <a href="https://www.facebook.com/didacticaEc/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF /></a>
                            <a href="https://www.linkedin.com/company/editorial-didactica/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                                <FaLinkedinIn /></a>
                            <a href="https://www.youtube.com/@editorialdidactica4477" aria-label="YouTube" target="_blank" rel="noopener noreferrer">
                                <FaYoutube /></a>
                            <a href="https://www.instagram.com/didacticaec/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                                <FaInstagram /></a>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default WholeContent;
