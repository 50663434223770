import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import styles from './LoginHeader.module.css';
import logo from './images/DidacticaLogo.png'; // Asegúrate de que la ruta sea correcta y la imagen del logo esté en la carpeta adecuada

const LoginHeader = () => {
  const navigate = useNavigate(); // Inicializar useNavigate

  const handleRegisterClick = () => {
    navigate('/register'); // Redirigir a la ruta /register
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoColumn}>
        <img src={logo} alt="didáctica editorial" className={styles.logo} />
      </div>
      <div className={styles.buttonColumn}>
        <button className={styles.registerButton} onClick={handleRegisterClick}>Registrarse</button>
      </div>
    </header>
  );
};

export default LoginHeader;
