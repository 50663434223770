import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styles from './VerifyMainContent.module.css';
import AsterImage from './images/VerificacionAster.png';

const VerifyMainContent = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setToken(token);
    } else {
      setMessage('Token no válido.');
    }
  }, [searchParams]);

  const handleVerify = async () => {
    if (token) {
      try {
        const response = await axios.get(`https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/verify?token=${token}`);
        if (response.data.message === 'Usuario verificado exitosamente') {
          setMessage('Verificación exitosa. \n Redirigiendo a la página de inicio de sesión...');
          setTimeout(() => {
            navigate('/login'); // Usa useNavigate para redirigir
          }, 4000); // Espera 3 segundos antes de redirigir a /login
        } else {
          setMessage(response.data.message);
        }
      } catch (error) {
        setMessage('Error al verificar la cuenta.');
      }
    } else {
      setMessage('Token no válido.');
    }
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.verificationBox}>
        <div className={styles.asterContainer}>
          <img src={AsterImage} alt="Aster" className={styles.asterImage} />
        </div>
        <div className={styles.verificationTitle}>Verificación de cuenta</div>
        <div className={styles.verificationMessage}>Da clic en este botón para verificar tu cuenta.</div>
        <button onClick={handleVerify} className={styles.verifyButton}>Verificar</button>
        {message && <div className={styles.message}>{message}</div>}
      </div>
    </div>
  );
};

export default VerifyMainContent;
