import React from 'react';
import styles from './RegisterHeader.module.css';
import logo from './images/DidacticaLogo.png';

const RegisterHeader = () => {
  return (
    <header className={styles.header}>
      <img src={logo} alt="Didáctica Editorial" className={styles.logo} />
    </header>
  );
};

export default RegisterHeader;
