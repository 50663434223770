import React from 'react';
import Header from './Header/HomeHeader';
import Footer from '../LandingPage/Footer/Footer';
import HomeMainContent from './MainContent/HomeMainContent';
import ReportBugButton from "../Report/ReportBugButton";
import styles from './Home.module.css';

const Home = () => {
  console.log('Renderizando Home');
  return (
    <div className={styles.homePage}>
      <Header />
      <HomeMainContent />
      <Footer />
      <ReportBugButton/>
    </div>
  );
};

export default Home;
