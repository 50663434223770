import React from 'react';
import './Header.modules.css';
import logo from './images/LogoDidactica.png'; // Asegúrate de que la ruta sea correcta y la imagen del logo esté en la carpeta adecuada

const Header = () => {
  return (
    <header className="header">
      <div className="logo-column">
        <img src={logo} alt="didáctica editorial" className="logo" />
      </div>
      <div className="welcome-column">
        <div className="welcome">¡Bienvenido a la revolución educativa!</div>
      </div>
    </header>
  );
};

export default Header;
